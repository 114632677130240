import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pm-ep-yes-no-dialog',
  templateUrl: './ep-yes-no-dialog.component.html',
  styleUrls: ['./ep-yes-no-dialog.component.css']
})
export class EpYesNoDialogComponent implements OnInit {
  @Input() set shouldShow(value: boolean) {
    this._shouldShow = value;
    this.shouldShowChange.emit(this._shouldShow);
  }
  get shouldShow(): boolean {
    return this._shouldShow;
  }
  @Input() title: string = null;
  @Input() cancelBtnTitle = 'No';
  @Input() confirmBtnTitle = 'Yes';
  @Input() iconName: string = null;
  @Input() size: number = null;
  @Input() isYesButtonDisabled = false;
  @Input() isBackDropStatic: boolean = false;
  @Input() isUploadFileModal: boolean = false;
  @Input() isRerunModal: boolean = false;
  @Input() isResponseRerunModal: boolean = false;
  @Input() showCloseDeleteModal: boolean = true;
  @Output() shouldShowChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dialogClose: EventEmitter<any> = new EventEmitter();
  @Output() dialogCancel: EventEmitter<any> = new EventEmitter();
  @Output() dialogConfirm: EventEmitter<any> = new EventEmitter();
  private _shouldShow = false;
  constructor() { }

  ngOnInit(): void {
  }

  onDialogClose(): void {
    this.shouldShow = false;
    this.dialogClose.emit();
  }

  onDialogCancel(): void {
    this.dialogCancel.emit();
    this.onDialogClose();
  }

  onDialogConfirm(): void {
    if (this.isYesButtonDisabled) {
      return;
    }

    this.dialogConfirm.emit();
    if (!this.isUploadFileModal && !this.isRerunModal && !this.isResponseRerunModal && this.showCloseDeleteModal)
      this.onDialogClose();
  }
}
