<pm-ep-dialog [(shouldShow)]="shouldShow"
              [title]="title"
              [size]="size"
              [iconName]="iconName"
              [footerTemplate]="yesNoDialogFooterTemplate"
              [isBackDropStatic]="isBackDropStatic"
              (dialogClose)="onDialogClose()">
  <ng-content></ng-content>
</pm-ep-dialog>

<ng-template #yesNoDialogFooterTemplate>
  <div class="gw-flex-container  gw-yes-no-dialog">
    <div class="gw-flex-item gw-flex-left">
      <button class="gw-no-shadow"
              mat-raised-button
              color="primary"
              type="button"
              [disabled]="isYesButtonDisabled"
              (click)="onDialogConfirm()">{{confirmBtnTitle| uppercase}}</button>
      &nbsp;&nbsp;
      <button class="gw-no-shadow"
              mat-button
              type="button"
              (click)="onDialogCancel()">{{cancelBtnTitle| uppercase}}</button>
    </div>
  </div>
</ng-template>
