import { EventEmitter, Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class GlobalEventsHolderService {

  competitorUrlChangedEmitter = new EventEmitter<Object>();

  constructor(    
  ) { }
}
