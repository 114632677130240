<div #containerRef>
  <ngx-charts-pie-grid
    class="chart-container"
    [view]="[elementRefWidth, height]"
    [scheme]="colorScheme"
    [results]="data"
    [animations]="animations"
    [tooltipDisabled]="tooltipDisabled"
    [tooltipText]="pieTooltipText"
    (select)="select($event)"
    (activate)="activate($event)"
    (deactivate)="deactivate($event)"
    (window:resize)="onResize($event)"
  >
  <ng-container *ngIf="isSocialNetworkDistribution">
    <ng-template #tooltipTemplate let-model="model">
      <div style="margin: 10px;">
        <span>{{ model.name }}</span>
        <br />
        <span>{{ model.value | number:'1.0':'en-US' }}</span>
      </div>
    </ng-template>
  </ng-container>
  </ngx-charts-pie-grid>
</div>
