<span class="range-slider min">
  <mat-slider [(value)]="value"
              (change)="valueChanged(value)"
              [step]="step"
              [max]="max"
              [min]="min"
              [thumbLabel]="thumbLabel"
              [disabled]="disabled"
               >
  </mat-slider>
</span>
