<form [formGroup]="productForm" (ngSubmit)="onFormSubmit()" novalidate>
  <pm-ep-form-dialog [dialogMaxWidth]="450" class="dialog" [sendButtonDisabled]="isSubmitFormDisabled()"
    [title]="dialogTitle" [submitBtnTitle]="dialogSubmitBtnTitle" (dialogClose)="cancelSettingsDialog()"
    [shouldShow]="shouldShowDialog" (shouldShowChange)="onShouldShowDialogChange($event)">
    <br />
    <br />
    <mat-form-field class="margin-right--m full-width">
      <input id="adminsSettingsDialogFirstNameInput" matInput formControlName="productName" type="text" maxlength="100"
        [placeholder]="('Name') + '*' ">
      <mat-error *ngIf="hasAnyErrors('productName')">
        {{getErrorMessage('productName ', 'Name')}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="margin-right--m full-width">
      <input id="adminsSettingsDialogFirstNameInput" matInput formControlName="productSku" type="text" maxlength="100"
        [placeholder]="('sku*')">
      <mat-error *ngIf="hasAnyErrors('productSku')">
        {{getErrorMessage('productSku', 'sku')}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="margin-right--m full-width">
      <input id="adminsSettingsDialogFirstNameInput" matInput formControlName="productBrand" type="text" maxlength="100"
        [placeholder]="('Brand') + '*' ">
      <mat-error *ngIf="hasAnyErrors('productBrand')">
        {{getErrorMessage('productBrand', 'Brand')}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="margin-right--m full-width">
      <input type="number" id="adminsSettingsDialogFirstNameInput" matInput formControlName="productPrice"
        maxlength="100" [placeholder]="('Price') + '*' ">
      <mat-error *ngIf="hasAnyErrors('productPrice')">
        {{getErrorMessage('productPrice', 'Price')}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="margin-right--m full-width">
      <input type="text" matInput formControlName="productImageUrl" maxlength="1024"
        [placeholder]="('ImageUrl') + '*' ">
      <mat-error *ngIf="hasAnyErrors('productImageUrl')">
        {{getErrorMessage('productImageUrl', 'ImageUrl')}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Category</mat-label>
      <input type="text" formControlName="productCategory" placeholder="choose Category" aria-label="Text" matInput
        [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let category of filteredCategories | async" [value]="category">
          {{category}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="margin-right--m full-width">
      <input type="number" id="adminsSettingsDialogFirstNameInput" matInput formControlName="productCost"
        maxlength="100" [placeholder]="('Cost')">
      <mat-error *ngIf="hasAnyErrors('productCost')">
        {{getErrorMessage('productCost', 'Cost')}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="margin-right--m full-width">
      <input id="adminsSettingsDialogFirstNameInput" matInput formControlName="productExternalId" type="text"
        maxlength="100" [placeholder]="('External Id')">
    </mat-form-field>
    <mat-form-field class="example-chip-list">
      <mat-label>UPC</mat-label>
      <mat-chip-list formControlName="productUpc" #chipList aria-label="Upc selection">
        <mat-chip *ngFor="let upc of currentProductUpc" [selectable]="selectable" [removable]="removable"
          (removed)="remove(upc)">
          {{upc}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input placeholder="New UPC..." [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
    </mat-form-field>
  </pm-ep-form-dialog>
</form>

<pm-ep-yes-no-dialog [title]="ConfirmDiscardChanges" (dialogConfirm)="onCancelYesNoDialog()"
  (dialogCancel)="onConfirmYesNoDialog()" [(shouldShow)]="shouldShowSubmitDialogText">
  {{ConfirmDiscardChangesMessage}}</pm-ep-yes-no-dialog>
