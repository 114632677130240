import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { GwObjectHelper } from '../helpers/gw-object.helper';
import { GwStringHelper } from '../helpers/gw-string.helper';

export class GwValidators {

  //#region Constants
  // Custom error names.
  public static readonly GW_EMAIL_ERROR = 'gwEmail';
  public static readonly GW_PASSWORD_ERROR = 'gwPassword';
  public static readonly GW_SPACES_ERROR = 'gwSpaces';
  public static readonly GW_URL_ERROR = 'gwUrl';
  public static readonly GW_DATE_RANGE_ERROR = 'gwDateRange';
  public static readonly GW_DATE_RANGE_START_DATE_REQUIRED_ERROR = 'gwDateRangeStartDateRequired';
  public static readonly GW_DATE_RANGE_END_DATE_OLDER_ERROR = 'gwDateRangeEndDateOlder';
  public static readonly GW_PASSWORD_MATCH_ERROR = 'gwPasswordMatch';
  public static readonly GW_PASSWORD_MATCH_PASSWORD_REQUIRED_ERROR = 'gwPasswordMatchPasswordRequired';
  public static readonly GW_PASSWORD_MATCH_CONFIRM_PASSWORD_NO_MATCH_ERROR = 'gwPasswordMatchConfirmPasswordNoMatch';

  // Build-in DOM error names.
  public static readonly DOM_REQUIRED_ERROR = 'required';
  public static readonly DOM_MAX_LENGTH_ERROR = 'maxlength';
  //#endregion

  public static gwEmail(control: AbstractControl)
    : ValidationErrors {
    // tslint:disable-next-line:max-line-length
    const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@]+(\.[^<>()\[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return GwValidators.isValid(control.value, REGEX_EMAIL, false) ? null : { gwEmail: { valid: false } };
  }

  public static validateEmail(email: string)
  : boolean {
    const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@]+(\.[^<>()\[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return GwValidators.isValid(email, REGEX_EMAIL, false);
  }

  public static gwLocation(control: AbstractControl)
  : ValidationErrors {
    const value: string = control.value;
    const isValid: boolean = value === null || value === '' || value.hasOwnProperty('formattedAddress');
    return isValid ? null : { gwLocation: { valid: false }} ;
  }

  public static gwPassword(control: AbstractControl)
    : ValidationErrors {
    // tslint:disable-next-line:max-line-length
    const REGEX_PASSWORD = /^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,})|((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,})|((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,})|((?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}))?$/;

    return GwValidators.isValid(control.value, REGEX_PASSWORD) ? null : { gwPassword: { valid: false } };
  }

  public static gwSpaces(control: AbstractControl)
    : ValidationErrors {
    // tslint:disable-next-line:max-line-length
    const REGEX_SPACES = /^[ ]+$/;

    return !GwValidators.isValid(control.value, REGEX_SPACES) ? null : { gwSpaces: { valid: false } };
  }

  public static gwUrl(control: AbstractControl)
    : ValidationErrors {
    // tslint:disable-next-line:max-line-length
    const REGEX_URL = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[A-Za-z0-9]+([\-\.]{1}[A-Za-z0-9]+)*\.[A-Za-z]{2,12}(:[0-9]{1,5})?(\/.*)?$/;

    return GwValidators.isValid(control.value, REGEX_URL, false) ? null : { gwUrl: { valid: false } };
  }

  public static gwDateRangeFn(startDateControlName: string = 'startDate', endDateControlName: string = 'endDate', startTimeControlName?: string, endTimeControlName?: string)
    : ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      let startTimeControl = null;
      let endTimeControl = null;
      const startDateControl = control.get(startDateControlName);
      const endDateControl = control.get(endDateControlName);
      if (startTimeControlName && endTimeControlName) {
        startTimeControl = control.get(startTimeControlName);
        endTimeControl = control.get(endTimeControlName);
      }

      if (!startDateControl || !endDateControl) {
        return null;
      }

      // Clear previous 'gwRangeDate*' errors from 'startDateErrors' and 'endDateErrors'.
      let startDateErrors =
        GwObjectHelper.removeKey(startDateControl.errors, GwValidators.GW_DATE_RANGE_START_DATE_REQUIRED_ERROR);
      let endDateErrors =
        GwObjectHelper.removeKey(endDateControl.errors, GwValidators.GW_DATE_RANGE_END_DATE_OLDER_ERROR);
      startDateControl.setErrors(startDateErrors);
      endDateControl.setErrors(endDateErrors);

      if ((!startDateControl.value && !endDateControl.value) ||
        (startDateControl.value && !endDateControl.value)) {
        return null;
      }

      if (!startDateControl.value && endDateControl.value) {
        // Update 'startDateErrors' with new error.
        startDateErrors = startDateErrors ? startDateErrors : {};
        startDateErrors[GwValidators.GW_DATE_RANGE_START_DATE_REQUIRED_ERROR] = { valid: false };
        startDateControl.setErrors(startDateErrors);

        // Return 'gwDateRange' error.
        return { gwDateRange: { valid: false } };
      }

      // Compare only dates (resetting time to zero).
      const startDate = (startDateControl.value instanceof Date) ? startDateControl.value : new Date(startDateControl.value);
      const endDate = (endDateControl.value instanceof Date) ? endDateControl.value : new Date(endDateControl.value);
      if (startTimeControl && endTimeControl && startTimeControl.value && endTimeControl.value) {
        const startTime: string = startTimeControl.value;
        const endTime: string = endTimeControl.value;
        startDate.setHours(parseInt(startTime.substring(0, 2)), parseInt(startTime.substring(3, 5)), 0, 0);
        endDate.setHours(parseInt(endTime.substring(0, 2)), parseInt(endTime.substring(3, 5)), 0, 0);

      }
      else {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
      }
      if (startDate > endDate) {
        // Update 'endDateErrors' with new error.
        endDateErrors = endDateErrors ? endDateErrors : {};
        endDateErrors[GwValidators.GW_DATE_RANGE_END_DATE_OLDER_ERROR] = { valid: false };
        endDateControl.setErrors(endDateErrors);

        // Return 'gwDateRange' error.
        return { gwDateRange: { valid: false } };
      }

      return null;
    };
  }

  public static gwPasswordMatchFn(passwordControlName: string = 'password', confirmPasswordControlName: string = 'confirmPassword')
    : ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const passwordControl = control.get(passwordControlName);
      const confirmPasswordControl = control.get(confirmPasswordControlName);

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      // Clear previous 'gwPasswordMatch*' errors from 'passwordErrors' and 'confirmPasswordErrors'.
      let passwordErrors =
        GwObjectHelper.removeKey(passwordControl.errors, GwValidators.GW_PASSWORD_MATCH_PASSWORD_REQUIRED_ERROR);
      let confirmPasswordErrors =
        GwObjectHelper.removeKey(confirmPasswordControl.errors, GwValidators.GW_PASSWORD_MATCH_CONFIRM_PASSWORD_NO_MATCH_ERROR);
      passwordControl.setErrors(passwordErrors);
      confirmPasswordControl.setErrors(confirmPasswordErrors);

      if ((!passwordControl.value && !confirmPasswordControl.value) ||
        (passwordControl.value && !confirmPasswordControl.value)) {
        return null;
      }

      if (!passwordControl.value && confirmPasswordControl.value) {
        // Update 'passwordErrors' with new error.
        passwordErrors = passwordErrors ? passwordErrors : {};
        passwordErrors[GwValidators.GW_PASSWORD_MATCH_PASSWORD_REQUIRED_ERROR] = { valid: false };
        passwordControl.setErrors(passwordErrors);

        // Return 'gwPasswordMatch' error.
        return { gwPasswordMatch: { valid: false } };
      }

      // Compare strings.
      const password = passwordControl.value ? passwordControl.value : '';
      const confirmPassword = confirmPasswordControl.value ? confirmPasswordControl.value : '';
      if (password !== confirmPassword) {
        // Update 'confirmPasswordErrors' with new error.
        confirmPasswordErrors = confirmPasswordErrors ? confirmPasswordErrors : {};
        confirmPasswordErrors[GwValidators.GW_PASSWORD_MATCH_CONFIRM_PASSWORD_NO_MATCH_ERROR] = { valid: false };
        confirmPasswordControl.setErrors(confirmPasswordErrors);

        // Return 'gwPasswordMatch' error.
        return { gwPasswordMatch: { valid: false } };
      }

      return null;
    };
  }

  // Get Actual Length (Including Special Characters as a single character)
  public static getActualLength(value: string): number {
    let strippedHtml = GwStringHelper.stripHtml(value);
    let actualValue = strippedHtml.replace(/\r?\n|\r/g, '');
    return Array.from(actualValue).length;
  }

  public static isActualLengthValid(control: AbstractControl, maxLength: number): ValidatorFn {
    let fnc = <ValidatorFn>(control) => {
      const length = this.getActualLength(control.value);
      return length > maxLength ? { isExceedLength: { valid: false } } : null;
    };

    return fnc;
  }

  //#region Privates
  private static isValid(value: string, regex: RegExp, isRequired: boolean = true)
    : boolean {
    if (isRequired) {
      return regex.test(value);
    }

    return (value === null) || (value === '') || regex.test(value);
  }
  //#endregion

}
