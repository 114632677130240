import {
  AfterViewChecked, AfterViewInit, ChangeDetectorRef,
  Component, ElementRef, Input, ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { ColorScheme } from 'app/common/constants';
import { PricingEntityType } from 'app/common/enums/pricing-entity-type.enum';
import { CompetitorsService } from 'app/common/services/competitors.service';
import { ProductsService } from 'app/common/services/product.service';
import * as shape from 'd3-shape';

@Component({
  selector: 'pm-bi-line-chart',
  templateUrl: './bi-line-chart.component.html',
  styleUrls: ['./bi-line-chart.component.scss']
})
export class BiLineChartComponent implements AfterViewInit, AfterViewChecked {
  view: any[] = null;

  @ViewChild('containerRef') containerRef: ElementRef;

  @Input() xAxisLabel;
  @Input() yAxisLabel;
  @Input() data;
  @Input() legend = true;
  @Input() legendTitle = "Legend";
  @Input() legendPosition = "below";
  @Input() pricingEntityType: PricingEntityType;

  @Input() height: number = 250;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  timeline = true;

  colorScheme = { ...ColorScheme };

  // line interpolation
  curves = {
    Basis: shape.curveBasis,
    'Basis Closed': shape.curveBasisClosed,
    Bundle: shape.curveBundle.beta(1),
    Cardinal: shape.curveCardinal,
    'Cardinal Closed': shape.curveCardinalClosed,
    'Catmull Rom': shape.curveCatmullRom,
    'Catmull Rom Closed': shape.curveCatmullRomClosed,
    Linear: shape.curveLinear,
    'Linear Closed': shape.curveLinearClosed,
    'Monotone X': shape.curveMonotoneX,
    'Monotone Y': shape.curveMonotoneY,
    Natural: shape.curveNatural,
    Step: shape.curveStep,
    'Step After': shape.curveStepAfter,
    'Step Before': shape.curveStepBefore,
    default: shape.curveLinear
  };
  curveType: string = 'Basis';
  curve: any = this.curves[this.curveType];

  onResize(event): void {
    if (this.elementRefWidth) {
      this.view = [this.elementRefWidth, this.elementHeight];
    }
  }

  constructor(private cdf: ChangeDetectorRef, private productService: ProductsService, private competitorService: CompetitorsService,
    private router: Router) {
    Object.assign(this, { data: this.data });
  }

  onSelect(pricingEntityName: string | any): void {

    if (!!pricingEntityName.series) {
      pricingEntityName = pricingEntityName.series
    }

    switch (this.pricingEntityType) {
      case PricingEntityType.Competitors:
        if (this.competitorService.isCompetitorExistsByName(pricingEntityName)) {
          this.productService.addProductsFilters(this.pricingEntityType, pricingEntityName);
          this.router.navigate(['/pages/platform/products']);
        }
        break;
    }
  }

  ngAfterViewChecked(): void {
    this.cdf.detectChanges();
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  get elementRefWidth(): number {
    return this.containerRef?.nativeElement?.offsetWidth;
  }

  get elementHeight(): number {
    const elementHeight = this.containerRef?.nativeElement?.offsetHeight;

    if(elementHeight < this.height)
      return this.height;

    return elementHeight;
  }
}
