import { CompetitorStatus } from 'app/common/enums/CompetitorStatus.enum';
import { ICompetitor } from 'app/common/interfaces/ICompetitor';
import { CompetitorType } from 'app/common/interfaces/competitors/CompetitorType';

export const vendors: ICompetitor[] = [
  {
    id: "1",
    name: 'Ebay',
    imageUrl: 'https://ecoprice.shop/resources/images/ebay.png',
    type: CompetitorType.SaleChannel,
    url: null,
    isSelected: true,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  },
  {
    id: "2",
    name: 'Aliexpress',
    imageUrl: 'https://ecoprice.shop/resources/images/aliexpress.png',
    type: CompetitorType.SaleChannel,
    url: null,
    isSelected: true,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  },
  {
    id: "3",
    name: 'Amazon',
    imageUrl: 'https://ecoprice.shop/resources/images/amazon.png',
    type: CompetitorType.SaleChannel,
    url: null,
    isSelected: true,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  },
  {
    id: "4",
    name: 'Gearbest',
    imageUrl: 'https://ecoprice.shop/resources/images/gearbest.png',
    isSelected: true,
    type: CompetitorType.SaleChannel,
    url: null,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  },
  {
    id: "5",
    name: 'Bangood',
    imageUrl: 'https://ecoprice.shop/resources/images/banggood.png',
    isSelected: true,
    type: CompetitorType.SaleChannel,
    url: null,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  },
  {
    id: "6",
    name: 'Geekbuying',
    imageUrl: 'https://ecoprice.shop/resources/images/geekbuying.png',
    isSelected: true,
    type: CompetitorType.SaleChannel,
    url: null,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  },
  {
    id: "7",
    name: 'Joybuy',
    imageUrl: 'https://ecoprice.shop/resources/images/joybuy.png',
    isSelected: true,
    type: CompetitorType.SaleChannel,
    url: null,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  },
  {
    id: "8",
    name: 'IHerb',
    imageUrl: 'https://ecoprice.shop/resources/images/iherb.png',
    isSelected: true,
    type: CompetitorType.SaleChannel,
    url: null,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  },
  {
    id: "9",
    name: 'Cdkeys',
    imageUrl: 'https://ecoprice.shop/resources/images/cdkeys.png',
    isSelected: true,
    type: CompetitorType.SaleChannel,
    url: null,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  },
  {
    id: "10",
    name: 'Asos',
    imageUrl: 'https://ecoprice.shop/resources/images/asos.png',
    isSelected: true,
    type: CompetitorType.SaleChannel,
    url: null,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  },
  {
    id: "11",
    name: 'BestBuy',
    imageUrl: 'https://ecoprice.shop/resources/images/bestbuy.png',
    isSelected: true,
    type: CompetitorType.SaleChannel,
    url: null,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active

  },
  {
    id: "12",
    name: 'NewEgg',
    imageUrl: 'https://ecoprice.shop/resources/images/newegg.png',
    isSelected: true,
    type: CompetitorType.SaleChannel,
    url: null,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  },
  {
    id: "13",
    name: 'Walmart',
    imageUrl: 'https://ecoprice.shop/resources/images/walmart.png',
    isSelected: true,
    type: CompetitorType.SaleChannel,
    url: null,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  },
  {
    id: "14",
    name: 'Target',
    imageUrl: 'https://ecoprice.shop/resources/images/target.png',
    isSelected: true,
    type: CompetitorType.SaleChannel,
    url: null,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  },
  {
    id: "15",
    name: 'Stockx',
    imageUrl: 'https://s29643.pcdn.co/wp-content/uploads/StockX-blog-thumbnail-4.png',
    isSelected: true,
    type: CompetitorType.SaleChannel,
    url: null,
    typeName: null,
    isActive: null,
    status: CompetitorStatus.Active
  }
];
