import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../services/session.service';

@Pipe({
  name: 'currencySymbolizer'
})
export class CurrencySymbolizerPipe implements PipeTransform {

  constructor(private sessionService: SessionService) { }
  transform(value: number, ...args: unknown[]): string {
    return this.sessionService.currencySymbol + value;
  }
}
