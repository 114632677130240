enum LandscapeType {
  Competitors = 0,
}

const competitorsTypeValue = LandscapeType.Competitors.valueOf();

const LandscapeTypeDescriptors = {};

LandscapeTypeDescriptors[competitorsTypeValue] = "All My Competitors";

export {
  LandscapeType,
  LandscapeTypeDescriptors
}

