<div class="padding-bottom-10" fxLayout="row" fxLayoutAlign="center center">
    <div >      
      <div style="outline: none; background-color: white; border-radius: 20px;"
      fxFlex="500px"
      fxFlex.lt-md="auto"
      fxHide.xs >
        <div>
          <input matInput [formControl]="searchCtrl" (search)="search()" style="color : black" class="outline-none px-4 py-3 border-0 outline-none w-full bg-transparent"
            placeholder="Enter product name..." type="search" >
        </div>
      </div>

      <div fxFlex="end">
        <button class="color-white" fxFlex="none" (click)="search()" mat-icon-button type="button">
          <mat-icon [icIcon]="icSearch"></mat-icon>
        </button>

        <button [matMenuTriggerFor]="columnFilterMenu" class="ml-4" class="color-white outline-none" fxFlex="none"
          mat-icon-button matTooltip="Filter Columns" type="button">
          <mat-icon [icIcon]="icFilterList"></mat-icon>
        </button>
      </div>
    </div>
  </div>


  <mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
    <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
      class="checkbox-item mat-menu-item">
      <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
        {{ column.label }}
      </mat-checkbox>
    </button>
  </mat-menu>
  