import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ITenant } from '../interfaces/tenant/ITenant';
import { IIntegration } from '../interfaces/IIntegration';
import { pricingPlans } from 'static-data/pricing-plans';
import { PricingPlanTypes } from '../enums/billing/PricingPlanTypes.enum';
// import * as crypto from 'crypto'

declare global {
    interface Window {
      Tawk_API:any;
    }
  }

@Injectable({
  providedIn: 'root'
})
export class ChatManagerService {  
  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  setChatAttributes(name, email, tenant : ITenant, integration : IIntegration){    
    setTimeout(() => {
      this.loadIntercom(name,email, tenant, integration);
    }, 6000);
  }

    
  openChat() {
    this.getWindow().Intercom('show');
  }


  createSha26Hmac(secret : string, value : string) : string{
    // let token = crypto.createHmac("sha256", secret).update(value).digest().toString('base64');

    return '';//token
  }

  loadTawkTo(name, email){
    const tawkAPI = this.document?.defaultView?.Tawk_API;
        if(tawkAPI){
            //const hash = this.createSha26Hmac()
            tawkAPI.setAttributes({
                'name' :  name,
                'email': email,                
                }, function (err) {
                    if(err)
                        console.error("an error has occured in setChatAttributes: " + err.message);
                    console.log("setChatAttributes completed");
                }); 
        }
  }
  
  loadIntercom(name, email, tenant : ITenant, integration : IIntegration) {
    const bootParams : any = {
      api_base: "https://api-iam.intercom.io",
      app_id: "dsra2smq",
      name: name,
      email: email,
      created_at: new Date().getTime(),
      company: {},
      custom_attributes: {}
    };
  
    let notes = [];
  
    // Ensuring tenant and integration are defined before accessing properties
    if (tenant) {
      const pricingPlanName = tenant.pricingPlan != null ? PricingPlanTypes[tenant.pricingPlan] : 'default-plan';
  
      if (tenant.name) {
        bootParams.company = {
          name: tenant.name,
          plan: pricingPlanName ?? "Unknown",
          company_id: tenant.id ?? tenant.name
        };
  
        notes.push(`Tenant Name: ${tenant.name}`);
        notes.push(`Pricing Plan: ${pricingPlanName}`);
      }
    }
  
    if (integration) {
      if (integration.shopUrl) {
        bootParams.company.website = integration.shopUrl;
        bootParams.company.shopUrl = integration.shopUrl;

        notes.push(`Shop URL: ${integration.shopUrl}`);
      }
  
      if (integration.productsCount) {
        bootParams.company.size = integration.productsCount;
        bootParams.company.productsCount = integration.productsCount;

        notes.push(`Products Count: ${integration.productsCount}`);
      }
  
      if (integration.shopCountry) {
        bootParams.company.country = integration.shopCountry;
        notes.push(`Shop Country: ${integration.shopCountry}`);
      }
    }
  
    // Concatenate all notes and assign them to both company and contact custom attributes
    if (notes.length > 0) {
      const concatenatedNotes = notes.join('; ');
      bootParams.company.notes = concatenatedNotes;
      bootParams.notes = concatenatedNotes; // Assuming 'custom_attributes' is the correct field for contact notes
    }
  
    // Assuming getWindow().Intercom is a method that exists and is accessible in the context this function is called
    this.getWindow().Intercom("boot", bootParams);
  }
  

  getWindow() : any{
    return this.document?.defaultView?.window;
  }

}
