<pm-ep-dialog [isPermanent]="false" [isOnBoarding]="true" (dialogClose)="startTour()" [closeBtnTitle]="''"
  [size]="dialogMaxWidth" [(shouldShow)]="shouldShow" [title]="title" [isBackDropStatic]="isBackDropStatic">
  <swiper [slidesPerView]="1" [spaceBetween]="300" (swiper)="onSwiper($event)" (slideChange)="onSlideChange($event)"
    [pagination]="{ clickable: true }">
    <div>
      <ng-template swiperSlide>
        <div class="center-div text box-padding"> Data is being gathered and your products are being synced, which might take up to one hour.
          <br> You’ll be notified by email upon completion.
        </div>
        <div class="center-div box-padding"><img class="image" src="../../.././assets/images/popup_sync.svg"></div>
        <div class="center-div box-padding">
          <button (click)="swipeNext()" class="center-div gw-hover button box-padding" mat-raised-button type="submit"
            color="primary">Next</button>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <h5 class="on-boarding center-div gw-font-500 gw-margin-no min-line-height">In the meantime, you can explore our platform🤩</h5>
        <br>
        <div class="center-div image box-padding"><img class="image" src="../../.././assets/images/popup_demo.svg">
        </div>
        <div class="center-div">
          <button class="gw-hover button center-div box-padding" (click)="startTour()" mat-raised-button
            color="primary">
            Let's start</button>
          <br><br>
        </div>
      </ng-template>
    </div>
  </swiper>
</pm-ep-dialog>
