import { trigger, state, style, transition, animate } from '@angular/animations';
export const dialogAnimation = [
    trigger('dialogAnimation', [
        state('0', style({ opacity: '0' })),
        state('1', style({ opacity: '1' })),
        transition('void => 1', [
            style({ opacity: '0' }),
            animate('200ms ease-out')]),
        transition('1 => void', [
            animate('200ms linear', style({ opacity: '0' }))
        ])
    ])
]
