import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PricingPlanTypes } from 'app/common/enums/billing/PricingPlanTypes.enum';
import { IPricingPlan } from 'app/common/interfaces/billing/pricingPlan';
import { ISearchableProduct } from 'app/common/interfaces/ISearchableProduct';
import { ProductsService } from 'app/common/services/product.service';
import { SessionService } from 'app/common/services/session.service';
import { Subscription } from 'rxjs-compat';
import { pricingPlans } from 'static-data/pricing-plans';

@Component({
  selector: 'products-limit-alert',
  templateUrl: './products-limit-alert.component.html',
  styleUrls: ['./products-limit-alert.component.scss']
})
export class ProductsLimitAlertComponent implements OnInit {

  currentPricingPlan: IPricingPlan;
  syncedProductsCount: number;
  productsPercentage: number;
  allProductsCount: number;

  constructor(
    private productsService: ProductsService,
    private sessionService: SessionService,
    private router: Router) { }

  ngOnInit(): void {
    this.setProductsData();    
    this.setCurrentPricingPlan();
    this.subscribeUserDataChange();
  }

  isShowProductsLimit(): boolean {
    if(this.sessionService.isDemoPlatform){
      return false;
    }
    else if (this.sessionService.currentTenant?.pricingPlan == PricingPlanTypes.Basic && this.syncedProductsCount < this.allProductsCount){
    return true;
    }
      
    return false;
  }

  navigateToBilling(): void {
    this.router.navigateByUrl('/pages/billing');
  }

  get ProductsLimit(): number {
    return this.currentPricingPlan?.productsLimit;
  }

  get AmountOfProducts(): number {
    return this.syncedProductsCount;
  }

  get progress(): number {
    if (!isNaN(this.syncedProductsCount) && !isNaN(this.allProductsCount)) {
      return Math.round((this.syncedProductsCount * 100) / this.allProductsCount);
    }
    return 0;
  }

  private async setProductsData() {
    let response = await this.productsService.getActiveProductsCount().toPromise();

    if(response?.count > 0){
      this.syncedProductsCount = response.count;
      this.allProductsCount = this.sessionService.currentIntegration?.productsCount;
    }    
  }

  private setCurrentPricingPlan(): void {
    let currentPricingPlan: PricingPlanTypes = this.sessionService.currentTenant?.pricingPlan;

    if (currentPricingPlan == null)
      return;

    this.currentPricingPlan = pricingPlans.find((pricingPlan: IPricingPlan) => {
      return currentPricingPlan == pricingPlan.pricingPlanType;
    });
  }

  private subscribeUserDataChange() {
    this.sessionService.userDataLoaderEventEmitter.subscribe(() => {
      this.setCurrentPricingPlan();
      this.allProductsCount = this.sessionService.currentIntegration?.productsCount;
    });
  }
}
