<form [formGroup]="conditionForm" novalidate>
  <div class="gw-flex-container">
    <h6 class="pricing-condition-sentence"> {{openingSentence}} </h6>
    <div class="entity-type-input" *ngIf="!isProductsGroupEntityType">
      <mat-form-field class="entities" matInput (click)="clearSearch()">
        <mat-select formControlName="entityIds" multiple>
          <mat-select-trigger>
            {{entityIds ? getEntityName(entityIds[0]) : ''}}
            <span *ngIf="entityIds?.length > 1" class="example-additional-selection">
              (+{{entityIds?.length - 1}} {{entityIds?.length === 2 ?
              'other' :
              'others'}})
            </span>
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search [formControl]="conditionForm.controls?.entityIdsMultiFilterCtrl"
              [placeholderLabel]="entityPlaceholderLabel" [noEntriesFoundLabel]="entityNoEntriesFoundLabel"
              [searching]="searching" id="entity-search-input">
            </ngx-mat-select-search>
          </mat-option>
          <div *ngIf="searching">
            <mat-spinner class="margin-left--m center-div" [diameter]="30"></mat-spinner>
          </div>
          <mat-option *ngFor="let entity of filteredEntitiesMulti | async" [value]="entity.value"
            (onSelectionChange)="onEntityChange($event)">
            {{entity.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="gw-flex-container margin-top--m">
    <div class="gw-flex-item entity-type-input" *ngIf="!isSingleProductPricingStrategy">
      <mat-form-field matInput>
        <mat-select (selectionChange)="updateEntityIds(true)" formControlName="entityType">
          <mat-option *ngFor="let entity of entityTypes" [value]="entity">
            {{getEntityTypes(entity)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="gw-flex-item">
      <h6 class="pricing-condition-sentence"> {{interchangeSentence}} </h6>
    </div>
    <div *ngIf="isShowMarginView && !isNoCompetitionProducts" class="gw-flex-item operators-width entity-type-input">
      <mat-form-field>
        <input matInput formControlName="margin" type="number" id="tentacles" name="tentacles" min="1" max="100">
      </mat-form-field>
    </div>
    <div *ngIf="isShowMarginView && !isNoCompetitionProducts" class="gw-flex-item operators-width entity-type-input">
      <mat-form-field>
        <mat-select matInput formControlName="marginType">
          <mat-option *ngFor="let margin of marginTypes" [value]="margin">
            {{getMarginView(margin)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="entity-type-input" *ngIf="!isNoCompetitionProducts">
      <mat-form-field>
        <mat-select matInput formControlName="positionOperatorType">
          <ng-container *ngFor="let operator of operatorTypes">
            <mat-option [value]="operator"
              *ngIf="!isPricingDiscount || (isPricingDiscount && operator != positionOperatorTypeEnum.Equal)">
              {{ getPositionOperator(operator) }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="gw-flex-container" *ngIf="!isPricingDiscount && !isNoCompetitionProducts">
    <div class="gw-flex-item">
      <h6 class="pricing-condition-sentence">{{getToOrTheSentence()}}</h6>
    </div>
    <div class="gw-flex-item margin-right--s entity-type-input">
      <mat-form-field matInput>
        <mat-select formControlName="pricingPositionType">
          <mat-option *ngFor="let position of positionTypes" [value]="position">
            {{getPositionTypeView(position)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="gw-flex-item">
      <h6 class="pricing-condition-sentence">{{OfMy}}</h6>
    </div>
    <div class="gw-flex-item entity-type-input">
      <mat-form-field class="competitors" matInput (click)="clearCompetitorSearch()">
        <mat-select formControlName="competitorNames" multiple>
          <mat-select-trigger>
            {{competitorNames ? getCompetitorName(competitorNames[0]) : ''}}
            <span *ngIf="competitorNames?.length > 1" class="example-additional-selection">
              (+{{competitorNames?.length - 1}} {{competitorNames?.length === 2 ?
              'other' :
              'others'}})
            </span>
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search [formControl]="conditionForm.controls?.competitorNamesMultiFilterCtrl"
              [placeholderLabel]="competitorPlaceholderLabel" [noEntriesFoundLabel]="competitorNoEntriesFoundLabel"
              id="competitor-search-input">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let competitor of filteredCompetitorEntitiesMulti
           | async" [value]="competitor.value"
            (onSelectionChange)="onCompetitorChange($event)">
            {{competitor.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
<pricing-exclusions *ngIf="isShowPricingExclusions"></pricing-exclusions>

