import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pm-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent {
  constructor() { }

  @Output() ValueChange = new EventEmitter<number>();
  @Input() value: number;
  @Input() max;
  @Input() min = 0.1;
  @Input() disabled : boolean;
  
  thumbLabel = true;
  step = 0.2;

  valueChanged(value: number): void {
    this.ValueChange.emit(value);
  }
}
