<div *ngIf="shouldShow" class="gw-popup" [@dialogAnimation]="'1'" (mousedown)="onBackgroundClick($event)" [ngClass]="hasXButton?'has-x-button':'true'">

  <div (mousedown)="$event.stopPropagation()" class="gw-popup-inner gw-page-text"
    [ngClass]="popupSectionCssClass" [style.max-width.px]="fullSize?972:smallSize?300:size?size:550">
    <button *ngIf="!isPermanent" type="button" class="x-button btn-link-focusable" (click)="onDialogClose()">
      <!-- <mat-icon *ngIf="hasXButton">close</mat-icon> -->
      <b>X</b>
    </button>
    <div class="gw-popup-section gw-popup-section__content">
      <div *ngIf="iconName" class="gw-popup-icon gw-justify-center padding-bottom--xs">
        <i class="gw-popup-icon-inner material-icons">{{iconName}}</i>
      </div>
      <h3 *ngIf="title && !isOnBoarding && !isPricingPlanUpgrade && !isPricingStrategy && !isPricingDiscount" class="gw-h2 gw-font-500 gw-margin-no min-line-height">{{title}}</h3>
      <h3 *ngIf="title && !isOnBoarding && !isPricingPlanUpgrade && isPricingStrategy && !isPricingDiscount" class="pricing-strategy gw-h2 gw-font-500 gw-margin-no min-line-height">{{title}}</h3>
      <h3 *ngIf="title && !isOnBoarding && !isPricingPlanUpgrade && !isPricingStrategy && isPricingDiscount" class="pricing-discount gw-h2 gw-font-500 gw-margin-no min-line-height">{{title}}</h3>
      <h5 *ngIf="title && isOnBoarding && !isPricingPlanUpgrade && !isPricingStrategy && !isPricingDiscount" class="on-boarding center-div gw-font-500 gw-margin-no min-line-height">{{title}}</h5>
      <h5 *ngIf="title && !isOnBoarding && isPricingPlanUpgrade && !isPricingStrategy && !isPricingDiscount" class="pricing-plan-upgrade center-div gw-font-500 gw-margin-no min-line-height">{{title}}</h5>
      <div class="gw-popup-content padding-top--xs">
        <ng-content>
        </ng-content>
      </div>
    </div>
    <div *ngIf="hasControlsSection" class="gw-popup-section">
      <ng-container *ngTemplateOutlet="footerTemplate ? footerTemplate : defaultFooterTemplate"></ng-container>
    </div>
  </div>
</div>

<ng-template #defaultFooterTemplate>
  <div class="gw-flex-container">
    <div class="gw-flex-item">
      <button *ngIf="!isPermanent" mat-button color="primary" type="button" (click)="onDialogClose()">{{closeBtnTitle}}</button>
    </div>
  </div>
</ng-template>
