<form class="margin-top--l" [formGroup]="addCompetitorForm" (ngSubmit)="onFormSubmit()" novalidate>
  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!isMobile">
    <span class="font margin-left--l">
      Add Url For Monitoring
    </span>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" class="margin-left--l" *ngIf="!isMobile">
    <mat-form-field class="margin-right--m competitor-form">
      <input id="competitorUrl" matInput formControlName="competitorUrl" type="text"
        [placeholder]="('Url to add') + '*'">
      <mat-error *ngIf="hasAnyErrors('competitorUrl')">
        {{getErrorMessage('competitorUrl ', 'Competitor Url')}}
      </mat-error>
    </mat-form-field>
    <button disableElementForDemoPlatform type="submit"
      [matTooltip]="'Add Url'" class="add-url gw-hover" [disabled]="addCompetitorForm.invalid || isDemoPlatform">
      <img src="../../../assets/images/add.svg" height="70" />
    </button>
  </div>

  <div *ngIf="shouldShowTable">
    <table mat-table [dataSource]="_dataSource" matSort>

      <!-- Name Column -->
      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="60%"> Url </th>
        <td mat-cell *matCellDef="let element" width="60%">
          <div class="ellipses">
            <a href="{{element.url}}" target="_blank">
              {{element.url?.slice(0,50)}}
            </a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="30%"> Status </th>
        <td mat-cell *matCellDef="let element" width="30%">
          <div *ngIf="element.status != null;else show_status_loading" class="with-icon"
              [ngClass]="{'failed': element.status.toLowerCase() == 'failed', 'success': element.status.toLowerCase() == 'success', 'in-review': element.status.toLowerCase() == 'in review'}">
            <ng-container *ngIf="element.status.toLowerCase() == 'failed'">
              <mat-icon style="margin-right: 5px;">cancel</mat-icon> Failed
            </ng-container>
            <ng-container *ngIf="element.status.toLowerCase() == 'success'">
              <mat-icon style="margin-right: 5px;">check_circle</mat-icon> Success
            </ng-container>
            <ng-container *ngIf="element.status.toLowerCase() == 'in review'">
              <mat-icon style="margin-right: 5px;">warning</mat-icon> In Review
            </ng-container>
          </div>
          <ng-template #show_status_loading>
            <div>
              <mat-spinner [diameter]="30"></mat-spinner>
            </div>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th class="delete-th" mat-header-cell *matHeaderCellDef mat-sort-header width="10%"></th>
        <td mat-cell *matCellDef="let element" class="delete-td" (click)="onDeleteRow(element)" width="10%">
          <div>
            <nb-icon class="clickable delete-icon" icon="trash-2-outline"></nb-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns:columns;"></tr>
    </table>

    <table>
      <tr *ngIf="isLoading">
        <td style="text-align: center;padding: 10px;" [attr.colspan]="columns.length">
          Loading, please wait...
        </td>
      </tr>
      <tr *ngIf="(_dataSource && _dataSource.data.length <= 0) && !isLoading">
        <td style="text-align: center;padding: 10px;" [attr.colspan]="columns.length">
          No urls found.
        </td>
      </tr>
    </table>

  </div>

</form>

<delete-competitor-url-dialog [shouldShowDialog]="shouldShowDeleteDialog"
  [targetCompetitorUrl]="competitorUrlForDeletion" (onDialogClose)="deleteCompetitorUrlDialogClose()"
  (onFinishEvent)="onDeleteSuccess()">
</delete-competitor-url-dialog>
