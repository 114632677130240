import { Injectable } from "@angular/core";
import { NbComponentStatus, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { ChatManagerService } from "./chatManager.service";

@Injectable({
    providedIn: 'root'
})
export class ToastrService {
    constructor(
        private toastrService: NbToastrService,
        private chatService : ChatManagerService
    ) {      
    }

    showToast(message: string, title: string, status: NbComponentStatus, position: NbGlobalPosition, openChat: boolean = false): void {
        if (status == "danger") {
            this.toastrService.show(message, title, { status: status, position: position, duration: 20000 });
            if (openChat)
                this.chatService.openChat();
        } else 
            this.toastrService.show(message, title, { status: status, position: position, duration: 5000 });
    }
}