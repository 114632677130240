<mat-form-field class="example-chip-list">
  <mat-label>{{title}}</mat-label>
  <mat-chip-list #chipList aria-label="Item selection" [disabled]="isDisableAbnormalFilter">
    <mat-chip *ngFor="let item of items" [selectable]="selectable" [removable]="isRemovable(item)" (removed)="remove(item)">
      {{item[filterAttribute]}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input [placeholder]="placeholder" #ItemInput [formControl]="itemCtrl" [matAutocomplete]="auto"
      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" panelWidth="auto">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item[filterAttribute]">
      <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{element:item}">
      </ng-container>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
