import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColorScheme } from 'app/common/constants';
import { PricingEntityType } from 'app/common/enums/pricing-entity-type.enum';
import { CompetitorsService } from 'app/common/services/competitors.service';
import { ProductsService } from 'app/common/services/product.service';

@Component({
  selector: 'pm-vertical-bar-chart',
  templateUrl: './vertical-bar-chart.component.html',
  styleUrls: ['./vertical-bar-chart.component.scss']
})

export class VerticalBarChartComponent implements AfterViewInit, AfterViewChecked {
  multi: any[];
  view: any[] = null;
  height: number = 300;
  @Input() xAxisLabel;
  @Input() yAxisLabel;
  @Input() data;
  @Input() legendTitle;
  @Input() showLegend = true;
  @Input() showYAxisLabel = false;
  @Input() showXAxisLabel = true;
  @Input() showGridLines = false;
  @Input() pricingEntityType: PricingEntityType;
  @Input() barPadding: number = 10;
  @Input() isTrafficEngagement: boolean = false;
  @Input() isMarketingChannelsDistribution: boolean = false;
  @Input() colorScheme = { ...ColorScheme };
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  legendPositon = 'below';


  @ViewChild('containerRef') containerRef: ElementRef;


  constructor(private detectChangesRef: ChangeDetectorRef, private productService: ProductsService,
    private competitorService: CompetitorsService, private router: Router) {
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }


  ngAfterViewChecked(): void {
    this.detectChangesRef.detectChanges();
  }

  onSelect(pricingEntityName: string): void {

    switch (this.pricingEntityType) {
      case PricingEntityType.Competitors:
        if (this.competitorService.isCompetitorExistsByName(pricingEntityName)) {
          this.productService.addProductsFilters(this.pricingEntityType, pricingEntityName);
          this.router.navigate(['/pages/platform/products']);
        }
        break;
    }
  }

  onResize(event): void {
    if (this.elementRefWidth) {
      this.view = [this.elementRefWidth, this.height];
      if (this.data?.length == 1) {
        this.barPadding = 200;
      }
    }
  }

  get elementRefWidth(): number {
    return this.containerRef?.nativeElement?.offsetWidth;
  }

  yAxisTickFormatting = (val: any) => {
    if (this.isTrafficEngagement) return (val = val + "K");
    else if (this.isMarketingChannelsDistribution) return (val = val + "%");
    else return val;
  }
}
