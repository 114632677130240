export enum PricePositioningType {
  Cheaper,
  Average,
  Higher,
  Cheapest,
  Highest,
  Equal
}

export const PricePositioningLabel = new Map<string, PricePositioningType>([
  ["Cheaper", PricePositioningType.Cheaper],
  ["Cheapest", PricePositioningType.Cheapest],
  ["Higher", PricePositioningType.Higher],
  ["Equal", PricePositioningType.Equal],
  ["Highest", PricePositioningType.Highest],
]);
