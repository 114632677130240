<div #containerRef>
    <ngx-charts-area-chart-stacked [view]="view" [scheme]="colorScheme" [schemeType]="schemeType" [results]="data"
        [animations]="animations" [legend]="showLegend" [legendTitle]="legendTitle" [legendPosition]="legendPosition"
        [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline" [xScaleMin]="xScaleMin" [xScaleMax]="xScaleMax"
        [yScaleMin]="yScaleMin" [yScaleMax]="yScaleMax" [showGridLines]="showGridLines" [roundDomains]="roundDomains" [tooltipDisabled]="tooltipDisabled"
        [curve]="curve" [trimXAxisTicks]="trimXAxisTicks" [trimYAxisTicks]="trimYAxisTicks" [rotateXAxisTicks]="rotateXAxisTicks"
        [maxXAxisTickLength]="maxXAxisTickLength" [maxYAxisTickLength]="maxYAxisTickLength" (select)="select($event)"
        (activate)="activate($event)" (deactivate)="deactivate($event)" (window:resize)="onResize($event)"
    >
    </ngx-charts-area-chart-stacked>
</div>