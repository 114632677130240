import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';

import { BILLING, LOGOUT } from "../../../common/constants"
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SessionService } from 'app/common/services/session.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/common/services/authentication.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  
  userMenu = [
    {
      title: BILLING, 
      data: BILLING,
      icon :  'credit-card-outline'
    },
    {
       title: LOGOUT, 
       data: LOGOUT,
       icon : 'log-out-outline'
    }
  ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private breakpointService: NbMediaBreakpointsService,
              private router : Router,
              public auth: AuthenticationService,
              public sessionService: SessionService,              
              @Inject(DOCUMENT) private doc: Document) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);


      this.sessionService.userDataLoaderEventEmitter.subscribe(onChange => {
        let currentTenantName = this.sessionService.currentTenant?.name;
        
        if(currentTenantName){
          this.userMenu.unshift({
            title: currentTenantName,
            data: currentTenantName,            
            icon : null
          });
        }   
        
        this.user = this.sessionService.currentUser;
      });     

      this.menuService.onItemClick().subscribe((event) => {
        if (event.item.data === LOGOUT) {
          this.auth.logout();
        }
        else if(event.item.data === BILLING){
          this.router.navigateByUrl('pages/billing');
        }
      });
  }

  get isDemoApp(){
    return this.sessionService.isDemoPlatform;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
