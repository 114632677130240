<div
  class="app-splash-screen"
  [ngStyle]="{ 
    left: windowWidth, 
    opacity: opacityChange,
    transition: splashTransition
  }"
  *ngIf="showSplash"
>
  <div class="app-splash-inner">
    <div class="app-logo blink-image"></div>
  </div>
</div>