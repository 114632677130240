import { EventEmitter, Injectable } from '@angular/core';
import { EpApiService } from '../../common/services/api/ep-api.service';
import { EpLoadingService } from '../../common/services/ep-loading.service';
import { vendors as competitorsConst } from '../../../static-data/vendors-data';
import { includes } from 'lodash';
import { Observable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { ICompetitorUrl } from '../interfaces/competitors/ICompetitorUrl';
import { PricingEntityType } from '../enums/pricing-entity-type.enum';
import { CompetitorType } from '../interfaces/competitors/CompetitorType';
import { ICompetitorActivationRequest } from 'app/common/interfaces/requests/ICompetitorActivationRequest';
import { ICompetitor } from '../interfaces/ICompetitor';
import { IChangeActivationStatusBulkRequest } from '../interfaces/requests/IChangeActivationStatusBulkRequest';
import { ICompetitorActivationChangeResult } from '../interfaces/responses/CompetitorActivationChangeResult';
import { handleError } from '../helpers/errors-handler-helper';
import { ISearchCompetitorUrlRequest } from '../interfaces/requests/ISearchCompetitorUrlRequest';
import { IRankData } from '../interfaces/IRankData';

@Injectable({
  providedIn: 'root'
})
export class CompetitorsService {

  competitorsListChangedEmitter = new EventEmitter<ICompetitor[]>();
  competitors: ICompetitor[] = [];
  activeCompetitors: ICompetitor[] = [];
  competitorsMap: Map<string, ICompetitor> = new Map<string, ICompetitor>();
  googleShoppingCompetitorEntity: ICompetitor = null;

  constructor(private epApiService: EpApiService,
    private epLoadingService: EpLoadingService) {
  }

  get CompetitorEntityType(): PricingEntityType {
    return PricingEntityType.Competitors
  }

  isCompetitorExistsByName(competitorName: string): boolean {
    return !!this.findCompetitorByName(competitorName);
  }

  getCompetitorsFromApi(): Observable<ICompetitor[]> {
    this.epLoadingService.start();
    return this.epApiService.get<ICompetitor[]>(`CompetitorActivations?shouldAddTenantCompetitors=true&shouldEnrichMatchingProductsCount=true`).pipe(
      map(data => {
        return data.map(competitor => {
          competitor.typeName = CompetitorType[competitor.type] == "SaleChannel" ? "Sale Channel" : CompetitorType[competitor.type];
          return competitor;
        });
      }),
      tap((data) => {
        this.sortAndLoadCompetitors(data);
        this.epLoadingService.stop();
      })
    );
  }

  private sortAndLoadCompetitors(data: ICompetitor[] = null) {
    if (data == null)
      data = this.competitors;

    let sortedData = data.sort((a, b) => {
      //if a is matched and b not return a before
      if (a.isActive && !b.isActive)
        return 1;

      //if b is matched and a not return b before
      if (!a.isActive && b.isActive)
        return -1;

      //equal
      if (a.NumberOfMatchingProducts == b.NumberOfMatchingProducts)
        return 0;

      //return the one with the higher score;
      return a.NumberOfMatchingProducts > b.NumberOfMatchingProducts ? 1 : -1;
    }).reverse();

    this.competitors = sortedData;
    this.competitorsMap = new Map<string, ICompetitor>();
    sortedData.forEach((competitor: ICompetitor) => {
      this.competitorsMap.set(competitor.name.toLowerCase(), competitor);
    });

    this.activeCompetitors = this.competitors.filter((competitor: ICompetitor) => competitor.isActive);

    this.googleShoppingCompetitorEntity = this.activeCompetitors.find(x => x.type == CompetitorType.SaleChannel && x.name == "Google Shopping");    
    
    this.competitorsListChangedEmitter.emit(sortedData);
  }

  loadCompetitors(): void {
    this.getCompetitorsFromApi().subscribe();
  }

  getCompetitors(vendorArray: string[] = null): ICompetitor[] {
    let vendors = [...competitorsConst];
    if (vendorArray != null && vendorArray.length > 0) {
      vendors = vendors.filter((vendor) => {
        return includes(vendorArray, vendor.name);
      })
    }
    return vendors;
  };

  async changeActivationForCompetitor(competitorId: string, competitorName: string, isActive: boolean) {
    var request: ICompetitorActivationRequest = {
      competitorId,
      isActive
    };

    try {
      this.epLoadingService.start();
      await this.epApiService.post("CompetitorActivations/ChangeCompetitorActivationStatus", request).toPromise();
      this.updateCompetitorList(competitorId, isActive);
      this.epLoadingService.stop(`Competitor ${competitorName} will update in the next 24 hours`);
    }
    catch (ex) {
      this.epLoadingService.stop(`Failed to activate competitor ${competitorName}, please upgrade your plan`);
      throw ex;
    }
  }

  changeActivationStatusBulkAsync(request: IChangeActivationStatusBulkRequest): Observable<ICompetitorActivationChangeResult[]> {
    this.epLoadingService.start();
    return this.epApiService.post("CompetitorActivations/ChangeActivationStatusBulk", request).pipe(
      tap((data: ICompetitorActivationChangeResult[]) => {
        let shouldReloadCompetitors = false;
        let succeeded = 0;
        data.forEach((item: ICompetitorActivationChangeResult) => {
          if (item.isSucceeded) {
            this.updateCompetitorList(item.competitorId, item.targetActivationStatus);
            shouldReloadCompetitors = true;
            succeeded++;
          }
        });

        if (shouldReloadCompetitors)
          this.competitorsListChangedEmitter.emit(this.competitors);

        if (data.length == succeeded)
          this.epLoadingService.stop(`Successfully changed activation for ${succeeded}/${data.length} competitors`);

        else if (data.length > succeeded)
          this.epLoadingService.stop(`Activation changed for ${succeeded}/${data.length} competitors, Please Upgrade plan or contact support`);
        else

          return data;
      }),
      catchError(err => {
        this.epLoadingService.stop();
        return handleError(err);
      }),
    );
  }

  isAnyCompetitorActive() {
    return this.competitors.find((competitor) => competitor.isActive) != null;
  }

  updateCompetitorList(competitorId: string, isActive: boolean) {
    const competitor = this.competitors.find(x => x.id == competitorId);
    if (competitor != null) {
      competitor.isActive = isActive;
    }
  };


  getCompetitorUrlsForManualAsync(searchableProductId: string): Observable<ICompetitorUrl[]> {
    this.epLoadingService.start();
    return this.epApiService.get(`Competitors/GetCompetitorUrls?searchableProductId=${searchableProductId}`).pipe(
      tap(() => this.epLoadingService.stop())
    );
  }

  getCompetitorUrlsAsync(): Observable<ICompetitorUrl[]> {
    this.epLoadingService.start();
    return this.epApiService.get(`Competitors/GetAllCompetitorUrls`).pipe(
      tap(() => {
        this.epLoadingService.stop();
        return;
      }),
      catchError(err => {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent)
          errorMessage = `An error has occurred: ${err.error.message}`;
        else
          errorMessage = `Server returned code: ${err.status}, error message is : ${err.message}`;
        this.epLoadingService.stop(errorMessage);
        return handleError(err);
      }),
    );
  }

  runCompetitorUrlSearch(request: ISearchCompetitorUrlRequest): Observable<any> {
    this.epLoadingService.start();
    return this.epApiService.post("Competitors/SearchCompetitorUrl", {
      "competitorUrlId": request.competitorUrlId,
      "searchableProductId": request.searchableProductId
    }).pipe(
      tap(() => {
        this.epLoadingService.stop();
        return;
      }),
      catchError(err => {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent)
          errorMessage = `An error has occurred: ${err.error.message}`;
        else
          errorMessage = `Server returned code: ${err.status}, error message is : ${err.message}`;
        this.epLoadingService.stop(errorMessage);
        return handleError(err);
      }),
    );
  }

  addCompetitorUrlAsync(searchableProductId: string, competitorUrl: string): Observable<void> {
    this.epLoadingService.start();
    return this.epApiService.post("Competitors/AddCompetitorUrl", {
      competitorUrl,
      searchableProductId
    }).pipe(
      tap(() => this.epLoadingService.stop("Url will be synced in the next 24 hours"))
    );
  }

  removeCompetitorUrlAsync(id: string) {
    this.epLoadingService.start();
    return this.epApiService.post("Competitors/RemoveCompetitorUrl", {
      id,
    }).pipe(
      tap(() => {
        this.epLoadingService.stop("Competitor url removed");
        return;
      }),
      catchError(err => {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent)
          errorMessage = `An error has occurred: ${err.error.message}`;
        else
          errorMessage = `Server returned code: ${err.status}, error message is : ${err.message}`;
        this.epLoadingService.stop(errorMessage);
        return handleError(err);
      }),
    );
  }

  updateCompetitor(competitor: ICompetitor): Observable<void> {
    this.epLoadingService.start();
    return this.epApiService.patch(`Competitors/${competitor.id}`, competitor).pipe(
      tap(() => this.epLoadingService.stop("Url will be synced in the next 24 hours"))
    );
  }

  findCompetitorByName(competitorName: string): ICompetitor {
    return this.activeCompetitors.find((competitor: ICompetitor) => competitor.name === competitorName)
  }

  isShowRank(data: IRankData): boolean {
    return data?.rank ? true : false;
  }

  rank(data: IRankData): number {
    return data?.rank;
  }

  isShowDiff(data: IRankData): boolean {
    return data?.diff ? true : false;
  }

  diff(data: IRankData): number {
    return data?.diff;
  }

  diffClass(data: IRankData): string {
    return data?.isPositiveDiff ? 'rank-up' : 'rank-down';
  }

  isUpDiff(data: IRankData): boolean {
    return data?.isPositiveDiff;
  }
}
