import { EventEmitter, Injectable } from '@angular/core';
import { EpApiService } from '../../common/services/api/ep-api.service';
import { EpLoadingService } from '../../common/services/ep-loading.service';
import { AuthService } from '@auth0/auth0-angular';
import { HttpErrorResponse } from '@angular/common/http';
import { IntegrationTypes } from '../enums/IntegrationTypes';
import { IIntegration } from '../interfaces/IIntegration';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ICollection } from '../interfaces/ICollection';
import { IMerchant } from '../interfaces/IMerchant';


const integrationUrlBuilder = {
}

integrationUrlBuilder[IntegrationTypes.Shopify.toString()] = {
  build: function (baseUrl, storeUrl, authToken) {
    return `${baseUrl}Integrations/InitShopifyIntegration/?ShopifyStoreURL=${storeUrl}&AuthToken=${authToken}`;
  }
}
integrationUrlBuilder[IntegrationTypes.Woo.toString()] = {
  build: function (baseUrl, storeUrl, authToken) {
    return `${baseUrl}WooIntegration/InitWooIntegration/?StoreURL=${storeUrl}&AuthToken=${authToken}`;
  }
}
integrationUrlBuilder[IntegrationTypes.Amazon.toString()] = {
  build: function (baseUrl, storeUrl, authToken) {
    return `${baseUrl}AmazonIntegration/InitForTenant/?AuthToken=${authToken}`;
  }
}

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  integrations: IIntegration[];
  integrationsLoaderEventEmitter = new EventEmitter<Object>();

  constructor(private epApiService: EpApiService,
    private epLoadingService: EpLoadingService,
    private authenticationService : AuthenticationService) {
  }

  async initIntegration(storeUrl: string, integrationType: IntegrationTypes) {
    this.epLoadingService.start();
    let accessToken = await this.authenticationService.getCurrentToken();
    let urlBuilder = integrationUrlBuilder[integrationType.toString()];

    if (urlBuilder == null)
      throw new Error(`integration ${integrationType.toString()} is not supported `);

    window.location.href = urlBuilder.build(this.epApiService.baseUrl, storeUrl, accessToken);
  }

  
  syncCollection(tenantId: string, targetCollectionName, addToActiveProducts : boolean) : Observable<any>{
    this.epLoadingService.start();
    return this.epApiService.post<any>(`Integrations/SyncShopifyCollections`, {
      tenantId : tenantId,
      shouldAddToActiveProductsByCollectionList : addToActiveProducts,
      "filterOptions" : {
        "name" : targetCollectionName,
        "shouldEnrichProductIds" : true
    },
    "shouldSyncProducts" : true
    }).pipe(
      tap(data => { return; }),
      catchError(this.epApiService.handleError)
    );   
  }

  getCollections(tenantId: string) : Observable<ICollection[]>{
    this.epLoadingService.start();
    return this.epApiService.post<any>(`Integrations/FilterShopifyCollections`, {
      tenantId : tenantId      
    }).pipe(
      tap(data => { return; }),
      catchError(this.epApiService.handleError)
    );   
  }

  getMerchants(tenantId: string) : Observable<IMerchant[]>{
    this.epLoadingService.start();
    return this.epApiService.get<any>(`Google/GetMerchantAccounts`, {
      tenantId : tenantId      
    }).pipe(
      tap(data => { return; }),
      catchError(this.epApiService.handleError)
    );   
  }

  async loadData() {
    this.epApiService.get<IIntegration[]>("Integrations").subscribe((integrations: IIntegration[]) => {
      this.integrations = integrations;
      this.integrationsLoaderEventEmitter.emit();
    });
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error has occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status},error message is : ${err.message}`;
    }
    console.error(errorMessage);
  }
}
