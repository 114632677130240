import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LegendPosition, ScaleType } from '@swimlane/ngx-charts';
import { GreenColorScheme } from 'app/common/constants';
import { ISeriesData } from 'app/common/interfaces/ISeriesData';
import * as shape from 'd3-shape';

@Component({
  selector: 'stacked-area-chart',
  templateUrl: './stacked-area-chart.component.html',
  styleUrls: ['./stacked-area-chart.component.scss']
})
export class StackedAreaChartComponent implements OnInit {
  view: [number, number];
  @Input() colorScheme = { ...GreenColorScheme };
  schemeType = ScaleType.Ordinal;
  selectedColorScheme: string;
  colorSets: any;
  animations: boolean = true;
  showLegend = true;
  legendTitle = '';
  legendPosition = LegendPosition.Below;
  gradient = false;
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  xAxisLabel = '';
  yAxisLabel = '';
  timeline = false;
  xScaleMin: any;
  xScaleMax: any;
  yScaleMin: number;
  yScaleMax: number;
  showGridLines = true;
  roundDomains = false;
  tooltipDisabled = false;
  curves = {
    Basis: shape.curveBasis,
    'Basis Closed': shape.curveBasisClosed,
    Bundle: shape.curveBundle.beta(1),
    Cardinal: shape.curveCardinal,
    'Cardinal Closed': shape.curveCardinalClosed,
    'Catmull Rom': shape.curveCatmullRom,
    'Catmull Rom Closed': shape.curveCatmullRomClosed,
    Linear: shape.curveLinear,
    'Linear Closed': shape.curveLinearClosed,
    'Monotone X': shape.curveMonotoneX,
    'Monotone Y': shape.curveMonotoneY,
    Natural: shape.curveNatural,
    Step: shape.curveStep,
    'Step After': shape.curveStepAfter,
    'Step Before': shape.curveStepBefore,
    default: shape.curveLinear
  };
  curveType: string = 'Basis';
  curve: any = this.curves[this.curveType];
  trimXAxisTicks: boolean = true;
  trimYAxisTicks: boolean = true;
  rotateXAxisTicks: boolean = true;
  maxXAxisTickLength: number = 16;
  maxYAxisTickLength: number = 16;
  
  @Input() data: ISeriesData[] = [];
  @Input() height: number = 400;

  @ViewChild('containerRef') containerRef: ElementRef;

  constructor(private cdf: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    this.cdf.detectChanges();
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  onResize(event): void {
    if (this.elementRefWidth) {
      this.view = [this.elementRefWidth, this.height];
    }
  }

  get elementRefWidth(): number {
    return this.containerRef?.nativeElement?.offsetWidth;
  }

  select(data) {
    console.log('Item clicked');
  }

  activate(data) {
    console.log('Activate');
  }

  deactivate(data) {
    console.log('Deactivate');
  }
}
