import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, ElementRef, Input, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ColorScheme } from 'app/common/constants';
import { PricingEntityType } from 'app/common/enums/pricing-entity-type.enum';
import { ProductsService } from 'app/common/services/product.service';

@Component({
  selector: 'pm-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements AfterViewInit, AfterViewChecked {

  multi: any[];
  view: any[] = null;
  @Input() height = 300;
  @Input() xAxisLabel;
  @Input() yAxisLabel;
  @Input() data;
  @Input() legendTitle;
  @Input() showLegend = true;
  @Input() itemsData: {};
  @Input() pricingEntityType: PricingEntityType;
  @Input() pricingEntityId: string;

  @ViewChild('containerRef') containerRef: ElementRef;


  constructor(private cdf: ChangeDetectorRef, private productService: ProductsService, private router: Router,) {
  }

  ngAfterViewChecked(): void {
    this.cdf.detectChanges();
  }

  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  gradient = true;


  showLabels = true;
  isDoughnut = false;
  legendPosition = 'below';

  colorScheme = { ...ColorScheme };

  onResize(event): void {
    if (this.elementRefWidth) {
      this.view = [this.elementRefWidth, this.height];
    }
  }

  onSelect(positioning: string): void {
    this.productService.addProductsFilters(this.pricingEntityType, this.pricingEntityId, positioning);
    this.router.navigate(['/pages/platform/products']);
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  get elementRefWidth(): number {
    return this.containerRef?.nativeElement?.offsetWidth;
  }

}
