
interface String {
  format(...replacements: string[]): string;
}


export class GwStringHelper {

  public static readonly at: string = '@';
  public static readonly space: string = ' ';

  public static splitIntoWords(text: string)
    : string[] {
    if (!text) {
      return [];
    }

    return text.trim().split(/\s+/);
  }

  public static stringFormat = (str: string, ...args: string[]) => str.replace(/{(\d+)}/g, (match, index) => args[index] || '')

  public static splice(originText: string, startIndex: number, delCount: number, newText: string) {
    return originText.slice(0, startIndex) + newText + originText.slice(startIndex + Math.abs(delCount));
  }

  public static removeHtmlTag(text: string): string {
    if (!text) {
      return '';
    }

    text = text.replace(/<a href=.*?><b>/g, '');
    return text.split('</b></a>').join('');
  }

  public static isPunctuation(character: string)
    : boolean {
    return !!character.match(/^[.,:;!?]/);
  }

  public static truncateByWords(text: string, countWords: number = 40, suffix: string = '...')
    : string {
    if (!text) {
      return '';
    }

    const words = GwStringHelper.splitIntoWords(text);

    // No truncation needed, exit with no change.
    if (words.length <= countWords) {
      return text;
    }

    const result = words.slice(0, countWords).join(' ');

    return `${result}${suffix}`;
  }

  public static format = function(str){
    var args = arguments;
    return str.replace(/{(\d+)}/g, function(match, number) { 
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
      ;
    });
  }  



  public static truncateByLength(text: string, maxLength: number = 200, suffix: string = '...', reserveLastWord: boolean = true)
    : string {
    if (!text) {
      return '';
    }

    let result = text.trim();

    // No truncation needed, exit with no change.
    if (result.length <= maxLength) {
      return text;
    }

    // Get index for truncation so that the last word will be reserved.
    let i = maxLength;
    if (reserveLastWord) {
      do {
        const nextChar = text.charAt(i);

        // Case where next character is a space, so it's ok to truncate.
        if (nextChar === ' ') {
          break;
        }

        i++;
      } while (i < text.length);

      // No truncation needed, exit with no change.
      if (result.length <= i) {
        return text;
      }
    }

    result = result.substr(0, i);

    return `${result}${suffix}`;
  }

  public static stripHtml(html: string) {
    if (!html) {
      return '';
    }

    const temp = document.createElement('div');
    temp.innerHTML = html;

    return temp.textContent || temp.innerText || '';
  }

  public static decodeHtml(html: string): string {
    if (!html) {
      return '';
    }
    let result = html.replace(/<div>/g, '').replace(/<\/div>/g, '');
    result = result.replace(/<p>/g, '').replace(/<\/p>/g, '');
    result = result.replace(/<span>/g, '').replace(/<\/span>/g, '');

    return result;
  }

  public static isNullOrWhiteSpace(text: string): boolean {
    if (!text) {
      return true;
    }

    return text.replace(/\s/g, '').length < 1;
  }

  public static isFirstCharacterSpace(text: string): boolean {
    return !!text && text.length > 0 && text[0] === ' ';
  }

  public static removeWhitespace(text: string): string {
    if (!text) {
      return '';
    }

    return text.replace(/\s/g, "");
  }

  public static isNullOrEmpty(text: string): boolean {
    return !text || !text.trim();
  }

}
