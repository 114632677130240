import { Injectable } from "@angular/core";

import { EpApiService } from "./api/ep-api.service";

@Injectable({
    providedIn: 'root'
  })
  export class InitializerService {
  
    authToken: string;
  
    constructor(){
    }
  
    async initialize(epApiService : EpApiService) : Promise<any>{
        this.authToken = await epApiService.init();        
    }
  }
  