<div class="gw-flex-container">
  <mat-checkbox class="exclude-products-checkbox" type="checkbox" [formControl]="shouldAddExcludeProducts"
    (change)="setShouldAddExcludeProducts($event)"></mat-checkbox>
  <h6 class="pricing-condition-sentence" [ngClass]="{'smaller-light-gray': isExcludeProductsDisabled}">
    {{excludeProductsSentence}}
  </h6>
  <div class="entity-type-input">
    <mat-form-field class="entities" matInput (click)="clearExcludeProductsSearch()">
      <mat-select [(value)]="selectedOption" [disabled]="isExcludeProductsDisabled">
        <mat-select-trigger>
          {{ excludedProductsLabel }}
        </mat-select-trigger>
        <mat-option>
          <ngx-mat-select-search [formControl]="excludeProductsEntityIdsMultiFilterCtrl"
            [placeholderLabel]="excludeProductsPlaceholderLabel"
            [noEntriesFoundLabel]="excludeProductsNoEntriesFoundLabel" id="exclude-products-search-input">
          </ngx-mat-select-search>
        </mat-option>
        <div *ngIf="searching">
          <mat-spinner class="margin-left--m center-div" [diameter]="30"></mat-spinner>
        </div>
        <mat-option *ngFor="let entity of filteredExcludedProductsEntitiesMulti | async" [value]="entity.value">
          <mat-checkbox [checked]="isProductSelected(entity)" (change)="onExcludedEntityChange($event, entity)">
            {{entity.title}}
          </mat-checkbox>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
  
<!-- <div *ngIf="!isExcludeProductsDisabled && selectedExcludedProducts.length > 0">
  <h6 class="pricing-condition-sentence">Products to Exclude</h6>
  <div class="excluded-products-container">
      <div class="excluded-product-item" *ngFor="let product of selectedExcludedProducts">
          <button type="button" class="remove-button" (click)="removeExcludedSelectedProduct(product)">
              <mat-icon>remove_circle_outline</mat-icon>
          </button>
          <span class="product-title">{{product.title}}</span>
      </div>
  </div>
</div> -->
