import { Injectable } from "@angular/core";
import jwt_decode from "jwt-decode";


@Injectable({
    providedIn: 'root'
  })
  export class SharedService {
  
    constructor(
    ) { }
  
    public isValidAccessToken(accessToken: string) : boolean{
        if(accessToken == null)
            return false;

        try{        
            let decoded: any = jwt_decode(accessToken);
            if (new Date(decoded.exp * 1000) > new Date()) {
            return true;
            }
        }
        catch{            
        }
        
        return false;
      }
  }

