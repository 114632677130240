import * as SignalR from '@microsoft/signalr';

import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthenticationService } from './authentication.service';
import { ISearchableProduct } from '../interfaces/ISearchableProduct';
import { ILastSearchMetadata } from '../interfaces/searchableProducts/ILastSearchMetadata';
import { ProductsService } from './product.service';
import { ICompetitorUrl } from '../interfaces/competitors/ICompetitorUrl';
import { GlobalEventsHolderService } from './globalEventsHolder.service';

const EndPoints = {
  addOrUpdateProduct : "addOrUpdateProduct",
  lastSearchUpdate : "lastSearchUpdate",
  competitorUrlEntityUpdated : "competitorUrlEntityUpdated"
}

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  connection: SignalR.HubConnection;
  isConnectionInitiated: boolean;

  constructor(private authenticationService : AuthenticationService,
              private productsService : ProductsService,
              private globalEventsHolderService : GlobalEventsHolderService) {
    this.isConnectionInitiated = false;        
  }  

  async connect(){    
    try{
      //only one initiation
      if(this.isConnectionInitiated)
        return;
      
      const accessToken = await this.authenticationService.getCurrentToken();
      if(accessToken == null){
        console.error("could not connect to signalr, token not exist");
      }

      this.connection = new SignalR.HubConnectionBuilder().withUrl(`${environment.baseApiUrl}hubs/searchableProducts?Authorization=${accessToken}`, {
        skipNegotiation: true,
        transport: SignalR.HttpTransportType.WebSockets
      })
      //retry every, stop rtying when acheiving null
      .withAutomaticReconnect([0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 20000, 30000])
      .build();

      this.subscribeToEndpoints();
      
      await this.connection.start();

      this.isConnectionInitiated = true;
    }
    catch(ex){
      console.error(ex); 
    }
  }

  private subscribeToEndpoints() {
    this.connection.on(EndPoints.addOrUpdateProduct, (searchableProduct : ISearchableProduct) => {
      try{
        this.productsService.addOrUpdateProductOnRepo(searchableProduct);        
      }
      catch(ex){
        console.error(ex);
      }
    });
    this.connection.on(EndPoints.lastSearchUpdate, (searchableProductId : string, lastSearchMetadata : ILastSearchMetadata) => {
      try{
        this.productsService.updateLastSearchForProduct(searchableProductId, lastSearchMetadata);
      }
      catch(ex){
        console.error(ex);
      }
    });

    this.connection.on(EndPoints.competitorUrlEntityUpdated, (updatedCompetitorUrlEntity : ICompetitorUrl) => {
      try{
        this.globalEventsHolderService.competitorUrlChangedEmitter.emit(updatedCompetitorUrlEntity);
      }
      catch(ex){
        console.error(ex);
      }
    });
  }
}
