import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { GwValidators } from '../../common/helpers/gw-validators.helper';
import { GwStringHelper } from '../../common/helpers/gw-string.helper';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { ProductsService } from 'app/common/services/product.service';
import { ISearchableProduct } from 'app/common/interfaces/ISearchableProduct';
import { ICategory } from 'app/common/interfaces/ICategory';

const MustBeLessThenCharacters = 'Must be last then #MaxLength#';
const AddNewProductTitle = 'Create new product';
const EditProductTitle = 'Edit Product:';
const AddNewProduct = 'Add Product';
const SaveChanges = 'Save changes';
@Component({
  selector: 'pm-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss']
})
export class ProductDialogComponent implements OnInit {

  @Output() shouldShowDialogChange: EventEmitter<boolean>;
  @Output() notifyUpdateProduct: EventEmitter<ISearchableProduct>;
  @Output() getDistributionList: EventEmitter<void>;
  @Input() productForEdit: ISearchableProduct;
  @Input() listsNames: Set<string>;
  @Input() editMode: boolean;
  @Input() shouldShowDialog: boolean;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  dialogTitle: string;
  dialogSubmitBtnTitle: string;
  ConfirmDiscardChanges = "Discard Changes";
  ConfirmDiscardChangesMessage = "Your changes will be lost, do you want to proceed?"
  dialogSubmitCancelEvent = false;
  currentProduct: ISearchableProduct;
  categoriesDict: { [categoryName: string]: string } = {};
  filteredCategories: Observable<string[]>;

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.currentProductUpc.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  remove(upc: string): void {
    const index = this.currentProductUpc.indexOf(upc);

    if (index >= 0) {
      this.currentProductUpc.splice(index, 1);
    }
  }

  productForm = new FormGroup({
    productId: new FormControl(''),
    productExternalId: new FormControl(''),
    productName: new FormControl('', [Validators.required, Validators.minLength(4)]),
    productSku: new FormControl('', [Validators.required, Validators.minLength(4)]),
    productImageUrl: new FormControl('', [Validators.required, Validators.pattern(/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i)]),
    productUpc: new FormControl([]),
    productBrand: new FormControl('', [Validators.required]),
    productPrice: new FormControl(''),
    productCost: new FormControl(''),
    productCategory: new FormControl(''),
  });

  shouldShowSubmitDialogText = false;
  distributionListId: number;
  recipientsDefaultValue: number[];
  originListName: string;
  zeroMembers = false;
  getAppErrorSubscription: Subscription;

  constructor(private productService: ProductsService) {
    this.shouldShowDialog = false;
    this.shouldShowDialogChange = new EventEmitter<boolean>();
    this.notifyUpdateProduct = new EventEmitter<ISearchableProduct>();
    this.getDistributionList = new EventEmitter<void>();
    this.dialogTitle = AddNewProductTitle;
    this.dialogSubmitBtnTitle = AddNewProduct;
  }

  ngOnInit(): void {
    this.initCategories();
  }

  setProductForEdit(product: ISearchableProduct): void {
    this.currentProduct = product;
    this.productForm.setValue({
      productId: product.id,
      productName: product.title,
      productSku: product.sku,
      productBrand: product.brand ?? "",
      productPrice: product.price,
      productImageUrl: product.imageUrl,
      productCost: product.cost == null ? 0 : product.cost,
      productUpc: product.upcs ?? [],
      productExternalId: product.externalId ?? "",
      productCategory: product.categoryId != null ? this.findCategoryName(product) : ""
    });
  }

  setEditMetaData(): void {
    this.dialogTitle = EditProductTitle;
    this.dialogSubmitBtnTitle = SaveChanges;
  }

  onShouldShowDialogChange(value: boolean): void {
    this.shouldShowDialog = value;
    this.shouldShowDialogChange.emit(value);
  }

  cancelSettingsDialog(): void {
    if (!this.isChanges()) {
      this.cleanForm();
    }
    else {
      this.shouldShowSubmitDialogText = true;
    }
  }

  isChanges(): boolean {
    return !(this.productForm.pristine);
  }

  cleanForm(): void {
    this.productForm.reset();
    this.dialogTitle = AddNewProductTitle;
    this.dialogSubmitBtnTitle = AddNewProduct;
    this.currentProductUpc = [];
  }

  onCancelYesNoDialog(): void {
    this.cleanForm();
  }

  onConfirmYesNoDialog(): void {
    this.onShouldShowDialogChange(true);
  }

  onFormSubmit(): void {
    this.setProduct();
    this.notifyUpdateProduct.emit({ ...this.currentProduct });
    this.currentProduct = null;
    this.cleanForm();
    this.markFormAsPristine();
    this.onShouldShowDialogChange(false);
  }

  setProduct(): ISearchableProduct {
    if (this.currentProduct != null) {
      this.currentProduct.externalId = this.currentProductExternalId,
        this.currentProduct.id = this.currentProductId;
      this.currentProduct.title = this.currentProductName;
      this.currentProduct.brand = this.currentProductBrand;
      this.currentProduct.sku = this.currentProductSku;
      this.currentProduct.price = parseFloat(this.currentProductPrice);
      this.currentProduct.cost = parseFloat(this.currentProductCost);
      this.currentProduct.upcs = this.currentProductUpc;
      this.currentProduct.imageUrl = this.currentProductImageUrl;
      this.currentProduct.categoryId = this.getCategoryId();
      this.currentProduct.newCategoryName = this.currentProduct.categoryId === null ? this.currentProductCategory : null;
    }
    else {
      let cost: number = parseFloat(this.currentProductCost);
      let categoryId: string = this.getCategoryId();
      this.currentProduct = {
        externalId: this.currentProductExternalId,
        id: null,
        title: this.currentProductName,
        brand: this.currentProductBrand,
        sku: this.currentProductSku,
        barcode : null,
        imageUrl: this.currentProductImageUrl,
        price: parseFloat(this.currentProductPrice),
        cost: isNaN(cost) ? 0 : cost,
        upcs: this.currentProductUpc,
        categoryId: categoryId,
        newCategoryName: categoryId === null ? this.currentProductCategory : null,
        isActive: true,
      };
    }
    return this.currentProduct;
  }

  isSubmitFormDisabled(): boolean {
    return !this.productForm.valid || GwStringHelper.removeWhitespace(this.currentProductName).length === 0;
  }

  get currentProductName(): string {
    return this.productForm.controls.productName.value;
  }

  get currentProductId(): string {
    return this.productForm.controls?.productId?.value ?? 0;
  }

  get currentProductExternalId(): string {
    return this.productForm?.controls?.productExternalId.value == '' ? null : this.productForm?.controls?.productExternalId.value;
  }

  get currentProductSku(): string {
    return this.productForm?.controls?.productSku?.value;
  }

  get currentProductImageUrl(): string {
    return this.productForm?.controls?.productImageUrl?.value;
  }

  get currentProductUpc(): string[] {
    return this.productForm?.controls?.productUpc?.value;
  }

  set currentProductUpc(upcs: string[]) {
    this.productForm?.controls?.productUpc.setValue(upcs);
  }

  get currentProductBrand(): string {
    return this.productForm?.controls?.productBrand?.value;
  }

  get currentProductPrice(): string {
    return this.productForm?.controls?.productPrice.value ?? null;
  }

  get currentProductCost(): string {
    return this.productForm?.controls?.productCost.value ?? null;
  }

  get currentCategory(): string {
    return this.productForm?.controls?.productCategory.value ?? null;
  }

  set currentCategory(categoryName: string) {
    this.productForm?.controls?.productCategory.setValue(categoryName.toLocaleLowerCase());
  }

  get currentProductCategory(): string {
    return this.productForm?.controls?.productCategory.value;
  }

  get categoryTitles(): string[] {
    return this.productService.categories.map((category) => { return category.name })
  }

  hasAnyErrors(formControlName: string): boolean {
    const control = this.productForm.get(formControlName);
    return !control ? false : !!control.errors;
  }

  markFormAsPristine(): void {
    this.productForm.markAsPristine();
  }

  getErrorMessage(formControlName: string, fieldName: string): string {
    const control = this.productForm.get(formControlName);
    if (!control) {
      return '';
    }
    if (control.hasError(GwValidators.DOM_MAX_LENGTH_ERROR)) {
      const maxLength = control.errors.maxlength.requiredLength.toString();
      return `${fieldName} ${MustBeLessThenCharacters.replace('#MaxLength#', maxLength)}`;
    }
  }

  private setCategoriesDict() {
    this.productService.categories.forEach((category: ICategory) => {
      this.categoriesDict[category.id] = category.name.toLowerCase();
    });
  }

  private getCategoryId(): string {
    let categoryId: string = this.categoriesDict[this.currentProductCategory.toLowerCase()];
    return categoryId ? categoryId : null;
  }

  private _filter(value: string): string[] {
    if (value == null) {
      return this.categoryTitles;
    }

    const filterValue = value.toLowerCase();
    return this.categoryTitles.filter(option => option.toLowerCase().includes(filterValue));
  }

  private findCategoryName(product: ISearchableProduct): string {
    let category: ICategory = this.productService.categories?.find((category: ICategory) => { return category.id === product.categoryId; })
    return category ? category.name : "";
  }

  private initCategories() {
    this.productService.categoriesListChangedEmitter.subscribe(() => {
      this.setCategoriesDict();
      this.filteredCategories = this.productForm.controls.productCategory.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    });
  }
}
