<button disableElementForDemoPlatform href="javascript:void(0)" class="file__upload" [ngClass]="cssClass"
  (click)="openUploadFilePopup();" (dragleave)="dragleave()" (dragenter)="dragenter()" (drop)="onDrop($event)"
  (dragover)="stopEvent($event)" [ngStyle]="{'color': color}">

  <i class="material-icons">{{icon}}</i>
  <img *ngIf="!icon" gw-hover src="../../../assets/images/batch.svg" height="20" />
  <span class="v-center">
    <ng-container *ngIf="noFileIsAttached">
      {{text}}
    </ng-container>
  </span>
  <span *ngIf="!hideOutput" class="v-center filename">
    <ng-container *ngIf="fileOutput && fileOutput.name">
      {{fileOutput.name}}
      <a class="v-center gw-text-link" (click)="cancelUploadedFile($event);">
        <button class="gw-no-button-style" aria-hidden="true">
          <mat-icon small-icon>close</mat-icon>
        </button>
      </a>
    </ng-container>
    <ng-container *ngIf="fileUploaded">
      {{fileUploaded}}
      <a class="v-center gw-text-link" (click)="deleteUploadedFile($event);">
        <span aria-hidden="true">
          <mat-icon small-icon>close</mat-icon>
        </span>
      </a>
    </ng-container>
  </span>
</button>

<mat-error *ngIf="errors.fileTooBig">
  {{'File too big' }}
</mat-error>
<mat-error *ngIf="errors.fileTooSmall">
  {{'File too small'}
</mat-error>
<mat-error *ngIf="errors.wrongType">
  {{'Please upload only allowed type'}}
  <small>({{accept}})</small>
</mat-error>
<input input aria-label="-file-input" #fileUploadElement type="file" [hidden]="true" [accept]="accept"
  (change)="chosenFile($event)" [multiple]="multiple" />
