import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';


@Component({
  selector: 'ep-select-filter',
  templateUrl: './ep-select-filter.component.html',
  styleUrls: ['./ep-select-filter.component.scss']
})
export class EpSelectFilterComponent<T>{

  @Input()
  selectedItem: T;

  @Input()
  itemsArray: T[];

  @Input()
  displayAttribute: string

  @Output()
  selectedChangeEventEmitter: EventEmitter<T> = new EventEmitter<T>();

  onSelectedValueChange(event: MatSelectChange) {
    let selectedItem = event.value as T;
    this.selectedItem = selectedItem;

    this.selectedChangeEventEmitter.emit(selectedItem);
  }
}
