<div *ngIf="isProducts else emptyTable">
  <div *ngIf="showFilter" class="gw-padding-left-10">
    <mat-form-field>
      <input matInput type="text" (keyup)="filterTable($event.target.value)" placeholder="Search">
    </mat-form-field>
    <ng-container *ngIf="(showExportOptions && shouldShowExportOptions || showColumnFilterOptions) && !isMobile">
      <div class="pull-right gw-padding-top-10">
        <button *ngIf="showColumnFilterOptions" class="margin-right--m" (click)="insightsProductTableSelect.open()"
          mat-stroked-button color="primary">
          <mat-icon>filter_list</mat-icon>
          <mat-select #insightsProductTableSelect class="columnSelect" multiple [value]="columnSelect"
            (selectionChange)="onChangeFilterColumns($event)">
            <ng-container *ngFor="let column of columnsForSelect">
              <mat-option [value]="column.property"
                [ngStyle]="{'display': column.property === 'checkbox' ? 'none' : 'flex' }">
                {{ column.label }}
              </mat-option>
            </ng-container>
          </mat-select>
        </button>
        <button *ngIf="showExportOptions && shouldShowExportOptions" mat-stroked-button color="primary"
          class="margin-right--m" (click)="exporter.exportTable(exportType.CSV, {fileName:'competitor products'})">
          <mat-icon>file_download</mat-icon>
          <span>Export Table</span>
        </button>
      </div>
    </ng-container>
  </div>

  <div
    *ngIf="!showFilter && !isMobile && (showExportOptions && shouldShowExportOptions || showColumnFilterOptions || showPercentagePriceConverter || showCreateReport)"
    class="pull-right gw-padding-top-10 gw-padding-left-10">
    <mat-button-toggle-group *ngIf="showPercentagePriceConverter"
      [ngClass]="(showExportOptions && shouldShowExportOptions || showColumnFilterOptions) ? '':'margin-right--m'"
      [value]="percentagePriceConverterValue" (change)="onChangePercentagePriceConverter($event)">
      <mat-button-toggle value="percentage">%</mat-button-toggle>
      <mat-button-toggle value="price">{{ currencySymbol }}</mat-button-toggle>
    </mat-button-toggle-group>
    <button *ngIf="showColumnFilterOptions" (click)="mainProductTableSelect.open()" mat-stroked-button color="primary"
      [ngClass]="(showExportOptions && shouldShowExportOptions) ? '':'margin-right--m'">
      <mat-icon>filter_list</mat-icon>
      <mat-select #mainProductTableSelect class="columnSelect" multiple [value]="columnSelect"
        (selectionChange)="onChangeFilterColumns($event)">
        <ng-container *ngFor="let column of columns">
          <mat-option [value]="column.property"
            [ngStyle]="{'display': column.property === 'checkbox' || column.property === 'product-variants' || column.property === 'actions' ? 'none' : 'flex' }">
            {{ column.label }}
          </mat-option>
        </ng-container>
      </mat-select>
    </button>
    <button *ngIf="showExportOptions && shouldShowExportOptions" mat-stroked-button color="primary"
      class="pull-right gw-padding-top-10 margin-right--m" (click)="exportProducts()">
      <mat-icon>file_download</mat-icon>
      <span>Export Table</span>
    </button>
    <button *ngIf="showCreateReport" mat-stroked-button color="primary"
      class="pull-right gw-padding-top-10" (click)="createReport()">
      <nb-icon icon="file-text-outline" pack="eva" [matTooltip]="'Create Report'"></nb-icon>
    </button>
  </div>

  <div *ngIf="!showFilter && numberOfSelectedProducts > 0" class="gw-padding-top-10 gw-padding-left-10">
    <span class="selected-products-count">
      {{ numberOfSelectedProducts }} {{ numberOfSelectedProducts == 1 ? "Product" : 'Products' }} selected
      <nb-icon class="delete-products" icon="trash-2-outline" [matTooltip]="'Delete'"
        (click)="deleteBulkProducts()"></nb-icon>
      <nb-icon *ngIf="isAdmin" class="update-products" icon="refresh" [matTooltip]="'Update Competitors Bulk'"
        (click)="updateCompetitorsBulk()"></nb-icon>
    </span>
  </div>

  <div class="products-table-width">
    <table mat-table matTableExporter matSort [dataSource]="_dataSource" #exporter="matTableExporter"
      [hiddenColumns]="[0, 5, 6, 7]" multiTemplateDataRows (matSortChange)="sortData($event)">

      <ng-container matColumnDef="expandedVariantDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="product-variants">
          <div class="example-element-detail"
            [@detailExpand]="element != null && variantExpandedElement != null && element == variantExpandedElement ? 'expanded' : 'collapsed'">
            <div class="padding-left--l padding-top-30"
              *ngIf="element != null && variantExpandedElement != null && element == variantExpandedElement">
              <product-variants [targetProduct]="element"></product-variants>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-th center-div"> Title </th>
        <td mat-cell *matCellDef="let element" class="padding-left--m">
          <div *ngIf="element.url">
            <a href={{element.url}} target="_blank">
              <div *ngIf="element.title; else elseBlock" [matTooltip]="element.title">{{element.title?.slice(0,50)}}
              </div>
              <ng-template #elseBlock>{{element.url?.slice(0,50)}}</ng-template>
            </a>
          </div>
          <div *ngIf="!element.url">
            {{element.title}}
          </div>
        </td>
      </ng-container>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="checkbox">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox class="margin-right--m" (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary">
          </mat-checkbox>
        </th>
        <td *matCellDef="let element" class="margin-right--m" mat-cell>
          <mat-checkbox (change)="$event ? selection.toggle(element) : null" (click)="$event.stopPropagation()"
            [checked]="selection.isSelected(element)" color="primary">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- ExternalID Column -->
      <ng-container matColumnDef="externalId">
        <th mat-header-cell *matHeaderCellDef> External Id </th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{element.externalId}}
          </div>
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{element.id}}
          </div>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="brand">
        <th mat-header-cell *matHeaderCellDef> Brand </th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{element.brand}}
          </div>
        </td>
      </ng-container>

      <!-- SKU Column -->
      <ng-container matColumnDef="sku">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU </th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{element.sku}}
          </div>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="model">
        <th mat-header-cell *matHeaderCellDef> Model </th>
        <td mat-cell *matCellDef="let element"> {{element.model}} </td>
      </ng-container>

      <!-- SKU Column -->
      <ng-container matColumnDef="barcode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Barcode </th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{element.barcode}}
          </div>
        </td>
      </ng-container>


      <!-- Symbol Column -->
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
        <td mat-cell *matCellDef="let element" class="price"> {{currencySymbol + element.price.toFixed(2)}} </td>
      </ng-container>

      <ng-container matColumnDef="currentSearchRank">
        <th mat-header-cell *matHeaderCellDef class="padding-th"> Search Rank </th>
        <td mat-cell *matCellDef="let element" class="price padding-th">
          <ng-container *ngIf="element.currentSearchRank && element.currentSearchRank > 0; else noRank">
            <pm-trend-arrow 
              [originalPrice]="element.previousSearchRank" 
              [value]="element.currentSearchRank" 
              [isRank]="true">
            </pm-trend-arrow>
          </ng-container>
          <ng-template #noRank>NA</ng-template>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="currentPageRank">
        <th mat-header-cell *matHeaderCellDef > Google Shopping Rank </th>
        <td mat-cell *matCellDef="let element" class="price">
          <ng-container *ngIf="element.currentPageRank && element.currentPageRank > 0; else noRank">
            <pm-trend-arrow 
              [originalPrice]="element.previousPageRank" 
              [value]="element.currentPageRank" 
              [isRank]="true">
            </pm-trend-arrow>
          </ng-container>
          <ng-template #noRank>NA</ng-template>
        </td>
      </ng-container>   

      <ng-container matColumnDef="currentPaidRank">
        <th mat-header-cell *matHeaderCellDef > Paid Rank </th>
        <td mat-cell *matCellDef="let element" class="price">
          <ng-container *ngIf="element.currentPaidRank && element.currentPaidRank > 0; else noRank">
            <pm-trend-arrow 
              [originalPrice]="element.previousPaidRank" 
              [value]="element.currentPaidRank" 
              [isRank]="true">
            </pm-trend-arrow>
          </ng-container>
          <ng-template #noRank>NA</ng-template>
        </td>
      </ng-container>   
    

      <ng-container matColumnDef="searchRank">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Search Rank </th>
        <td mat-cell *matCellDef="let element" class="price"> {{ element.searchRank ?? 'NA' }} </td>
      </ng-container>

      <ng-container matColumnDef="pageRank">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Google Shopping Rank </th>
        <td mat-cell *matCellDef="let element" class="price"> {{ element.pageRank ?? 'NA' }} </td>
      </ng-container>
      
      <ng-container matColumnDef="paidRank">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Paid Rank </th>
        <td mat-cell *matCellDef="let element" class="price"> {{ element.paidRank ?? 'NA' }} </td>
      </ng-container>

      <ng-container matColumnDef="discountedPrice">
        <th mat-header-cell *matHeaderCellDef> Discounted Price </th>
        <td mat-cell *matCellDef="let element" class="price"> {{currencySymbol + element.discountedPrice?.toFixed(2) ?? 0}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="unitPrice">
        <th mat-header-cell *matHeaderCellDef> Unit Price </th>
        <td mat-cell *matCellDef="let element" class="price"> {{currencySymbol + (element.unitPrice?.toFixed(2) ?? 0)}}
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="cost">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cost </th>
        <td mat-cell *matCellDef="let element" class="cost"> {{currencySymbol + (element.cost?.toFixed(2) ?? 0)}} </td>
      </ng-container>

      <!-- comapreatprice Column -->
      <ng-container matColumnDef="compareAtPrice">
        <th mat-header-cell *matHeaderCellDef> CompareAt Price </th>
        <td mat-cell *matCellDef="let element">
          <div class="price">
            {{currencySymbol + element.compareAtPrice?.toFixed(2) ?? 0}}
          </div>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef> Unit </th>
        <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="volume">
        <th mat-header-cell *matHeaderCellDef> Volume </th>
        <td mat-cell *matCellDef="let element"> {{element.volume}} </td>
      </ng-container>

      <!-- Cost Column -->
      <ng-container matColumnDef="packageQuantity">
        <th mat-header-cell *matHeaderCellDef>Package Quantity</th>
        <td mat-cell *matCellDef="let element" class="cost">
          <div class="center-div">
            {{ element.packageQuantity }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Create Date </th>
        <td mat-cell *matCellDef="let element"> {{element.createDate | date : "yyyy-MM-dd"}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="imageUrl">
        <th mat-header-cell *matHeaderCellDef> Image</th>
        <td class="image-td" mat-cell *matCellDef="let element">
          <pinch-zoom *ngIf="element.imageUrl" backgroundColor="white" disableZoomControl="disable">
            <img class="img-product" loading="lazy" [src]="element?.imageUrl" />
          </pinch-zoom>
          <!-- <div class="image-product" [style.background-image]="this.getUrl(element.imageUrl)"></div> -->
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="product-variants">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button [matTooltip]="'Variants Details'" class="gw-hover" mat-icon-button
            (click)="handleVariantExpansion(element)" *ngIf="element.variantsCount && element.variantsCount > 0">
            <span
              [ngClass]="shouldShowVariantExpansion(element) ? 'arrow-upward-icon-span' : 'arrow-downward-icon-span'">
              <i class="material-icons"
                [ngClass]="shouldShowVariantExpansion(element) ? 'arrow-upward-icon' : 'arrow-downward-icon'">{{
                shouldShowVariantExpansion(element) ? 'arrow_upward' : 'arrow_downward' }}</i>
            </span>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="imageUrlForInsights">
        <th mat-header-cell *matHeaderCellDef> Image</th>
        <td class="image-td" mat-cell *matCellDef="let element">
          <pinch-zoom *ngIf="element.imageUrl" backgroundColor="white" disableZoomControl="disable">
            <img loading="lazy" class="img-product" [src]="element?.imageUrl" />
          </pinch-zoom>
          <!-- <div class="image-product" [style.background-image]="this.getUrl(element.imageUrl)"></div> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="vendor">
        <th mat-header-cell *matHeaderCellDef> Competitor </th>
        <td mat-cell *matCellDef="let element">
          <div class="image-vendor margin-right-50" [style.background-image]="this.getLogoUrl(element.vendor)">
            <a (click)="emitItemForDetails(element)" class="competitors-text">
              {{ this.getLogoUrl(element.vendor) == 'none' ? element.vendor : ''}}
            </a>
          </div>
      </ng-container>

      <!-- Source Column -->
      <ng-container matColumnDef="source">
        <th mat-header-cell *matHeaderCellDef> Source </th>
        <td mat-cell *matCellDef="let element">
          <div class="image-vendor" [style.background-image]="this.getLogoUrl(element.source)">
            <a (click)="emitItemForDetails(element)" class="competitors-text">
              {{ this.getLogoUrl(element.source) == 'none' ? element.source : ''}}
            </a>
          </div>
      </ng-container>

      <ng-container matColumnDef="shippingPrice">
        <th mat-header-cell *matHeaderCellDef> Shipping Price </th>
        <td mat-cell *matCellDef="let element">
          <span style="margin-left: 2px">
            {{currencySymbol + element.shippingPrice?.toFixed(1)}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="tax">
        <th mat-header-cell *matHeaderCellDef> Tax </th>
        <td mat-cell *matCellDef="let element">
          <span style="margin-left: 2px">
            {{ getFormattedTax(element.tax) }}
          </span>
        </td>
      </ng-container>
      

      <ng-container matColumnDef="match">
        <th mat-header-cell *matHeaderCellDef> Match </th>
        <td mat-cell *matCellDef="let element"> {{normalizePercent(element.score)}} % </td>
      </ng-container>


      <ng-container matColumnDef="delete">
        <th class="delete-th" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="delete-td" (click)="onDeleteRow(element)">
          <nb-icon class="clickable delete-icon" icon="trash-2-outline"></nb-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="verified">
        <th mat-header-cell *matHeaderCellDef> Verified </th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle [disabled]="isDemoPlatform" [checked]="element.isVerified"
            (change)="onVerifiedSliderChange(element, $event)">
          </mat-slide-toggle>
          <!-- <nb-icon class="clickable delete-icon" icon="trash-2-outline"></nb-icon> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="isMatchedByAlgo">
        <th mat-header-cell *matHeaderCellDef> AlgoMatched </th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle [disabled]="isDemoPlatform" [checked]="element.isMatchedByAlgo">
          </mat-slide-toggle>
          <!-- <nb-icon class="clickable delete-icon" icon="trash-2-outline"></nb-icon> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="isAvailable">
        <th mat-header-cell *matHeaderCellDef> In Stock </th>
        <td mat-cell *matCellDef="let element">
          <div class="with-icon" [ngClass]="{'failed': !element.isAvailable, 'success': element.isAvailable}">
            <ng-container *ngIf="!element.isAvailable">
              <mat-icon style="margin-right: 5px;">cancel</mat-icon>
            </ng-container>
            <ng-container *ngIf="element.isAvailable">
              <mat-icon style="margin-right: 5px;">check_circle</mat-icon>
            </ng-container>
          </div>
          <!-- <nb-icon class="clickable delete-icon" icon="trash-2-outline"></nb-icon> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="extraInformation">
        <th mat-header-cell *matHeaderCellDef> Extra Information </th>
        <td mat-cell *matCellDef="let element">
          <span>
            {{ element.extraInformation }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="!isSyncing(element)" [ngTemplateOutlet]="actionTemplate"
            [ngTemplateOutletContext]="{element:element}">
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="competitors">
        <th class="competitors" mat-header-cell *matHeaderCellDef> Competitors </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="!isSyncing(element) && element.isActive" fxLayout="row" fxLayoutAlign="start center">
            <div style="padding-top: 8px;">
              <button [matTooltip]="'Add Competitor\'s Product URL'" class="gw-hover" mat-icon-button
                (click)="emitItemForManageUrls(element)">
                <span class="plus-icon-span">
                  <i class="material-icons plus-icon">add</i>
                </span>
              </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="element.averageDifferenceByVendors && element.averageDifferenceByVendors.length > 0">
              <div *ngFor="let vendorData of element.averageDifferenceByVendors?.slice(0, 3)">
                <div class="competitor-cell">
                  <pm-trend-arrow [originalPrice]="element.price"
                    [value]="isProductsPriceView ? vendorData.price : vendorData.averagePercentageDifference"></pm-trend-arrow>
                  <div (click)="emitItemForCompetitorsDetails(element)" class="image-vendor"
                    [style.background-image]="this.getLogoUrl(vendorData.vendor)">
                    <div class="competitors-text">
                      {{ this.getLogoUrl(vendorData.vendor) == 'none' ? vendorData.vendor : ''}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="competitor-cell other-competitiors" *ngIf="element.averageDifferenceByVendors.length > 3">
                <div (click)="emitItemForCompetitorsDetails(element)" class="image-vendor">
                  <div class="competitors-text">
                    (+{{element.averageDifferenceByVendors.length - 3}} {{element.averageDifferenceByVendors.length === 4 ? 'other' : 'others'}})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="margin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Market Diff
        </th>
        <td mat-cell *matCellDef="let element">
          <pm-trend-arrow *ngIf="!isSyncing(element) && element.isActive"
            [hasCompetition]="element.lastSearch?.isCompetitionFound"
            [value]="isProductsPriceView ? element.averagePriceDifference : element.averagePercentageDifference">
          </pm-trend-arrow>
          <p class="inactive" *ngIf="!element.isActive"> Inactive Product </p>
          <div *ngIf="isSyncing(element)" class="gw-flex-container">
            <div class="gw-flex-item">Syncing</div>
            <div class="gw-flex-item">
              <mat-spinner class="margin-left--m" [diameter]="20"></mat-spinner>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr (click)="onRowClick(element)" mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row"></tr>
      <tr mat-row *matRowDef="let element; columns: ['expandedVariantDetail']" class="example-detail-row"
        [hidden]="!shouldShowVariantExpansion(element)"></tr>
    </table>
    <div>
      <mat-paginator [length]="totalRows" [pageIndex]="pageIndex" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" showFirstLastButtons>
      </mat-paginator>
    </div>
    <div class="gw-float-right" *ngIf="isAdmin">
      <mat-label>Change Page Number (only admin)</mat-label>
      <input matInput placeholder="Page Number" (ngModelChange)="setPageChanged($event)" [(ngModel)]="adminPageIndex">
    </div>
  </div>
</div>

<ng-template #emptyTable>
  <div class="first-list-text gw-align-center gw-justify-center gw-flex" *ngIf="isDisplayNoProductsMessage">
    <div class="center-div text-center">
      <div class="add-new-product padding-top-60">
        {{ productsMessage }}
      </div>
    </div>
  </div>
</ng-template>

<pm-ep-yes-no-dialog [title]="deleteBulkProductsDialogTitle" (dialogConfirm)="onConfirmDeleteBulkProductsDialog()"
  (dialogCancel)="onCancelDeleteBulkProductsDialog()" [(shouldShow)]="shouldShowDeleteBulkProductsDialog">
  {{deleteBulkProductsDialogMessage}}</pm-ep-yes-no-dialog>