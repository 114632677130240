import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SearchableProductSourceTypes } from 'app/common/enums/SearchableProductSourceTypes';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from 'app/common/services/session.service';
import { VariantsSetupType } from 'app/common/enums/VariantsSetupType.enum';
import { ISearchableProduct } from 'app/common/interfaces/ISearchableProduct';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IntegrationTypes } from 'app/common/enums/IntegrationTypes';

const config: MatSnackBarConfig = { duration: 2000 }
@Component({
  selector: 'pm-action-items',
  templateUrl: './action-items.component.html',
  styleUrls: ['./action-items.component.scss']
})


export class ActionItemsComponent {

  @Input() isShowDownloadButton: boolean = true;
  @Input() isShowDetailsButton: boolean = true;
  @Input() isShowNotesButton: boolean = true;
  @Input() isShowEditButton: boolean = null;
  @Input() shouldShowDeleteButton: boolean = true;
  @Input() isShowAdminButton: boolean = true;
  @Input() shouldShowCopyButtons : boolean = true;
  @Input() shouldShowUpdateCompetitors: boolean = true;
  @Input() shouldShowActivateButton: boolean = true;
  @Input() shouldShowSeperateVariants : boolean = true;
  @Input() shouldShowApplyButton : boolean = true;
  @Input() shouldShowRollbackButton : boolean = true;
  @Input() shouldShowCopyButton : boolean = false;
  
  @Input() downloadButtonName : string = "Download";
  @Input() item: any;
  @Output() deleteItemEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() downloadItemEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() editItemEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitItemForView: EventEmitter<object> = new EventEmitter<Object>();
  @Output() emitItemForPricingSuggestionView: EventEmitter<object> = new EventEmitter<Object>();
  @Output() emitItemForApply: EventEmitter<object> = new EventEmitter<Object>();
  @Output() emitItemForRollback: EventEmitter<object> = new EventEmitter<Object>();
  @Output() emitItemForRerun: EventEmitter<object> = new EventEmitter<Object>();
  @Output() emitItemForUpdateCompetitorsEmitter: EventEmitter<object> = new EventEmitter<Object>();
  @Output() saveProduct: EventEmitter<ISearchableProduct> = new EventEmitter<ISearchableProduct>();
  @Output() separateVariantsEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitAdminButtonClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitItemForCopy: EventEmitter<object> = new EventEmitter<Object>();

  @Input() ConfirmDeleteMessage = 'Are you sure you want to delete this product? all the data will be lost';
  @Input() ConfirmDeleteProduct = 'Delete product';
  @Input() ConfirmApplyTitle = 'Apply Rule Form';
  @Input() ConfirmApplyMessage = 'Are you sure you want to apply rule now? price changes will take effect immediately';
  @Input() ConfirmRollbackTitle = 'Rollback Rule Form';
  @Input() ConfirmRollbackMessage = 'Are you sure you want to rollback rule now? all price changes will be reverted immediately';
  @Input() ConfirmRerunModalTitle = 'Run Competitor Url Search';
  @Input() ConfirmRerunModalMessage = 'Are you sure you want to rerun competitor url search?';
  @Input() ConfirmCopyTitle = 'Copy Strategy';
  @Input() ConfirmCopyMessage = 'Are you sure you want to copy this strategy?';
  @Input() isProductsActions: boolean = false;
  @Input() isPricingStrategyActions: boolean = false;
  @Input() isCompetitorsActions: boolean = false;
  @Input() isPricingDiscountActions: boolean = false;
  @Input() isCompetitorUrlsManagementActions: boolean = false;
  @Input() isShowCloseDeleteModal: boolean = true;
  @Input() isReportsActions: boolean = false;
  dateFormat: string;
  shouldShowDeleteProductText = false;
  shouldShowApplyModal = false;
  shouldShowRollbackModal = false;
  shouldShowRerunCompetitorUrlSearchModal = false;
  shouldShowCopyModal = false;

  constructor(public sessionService: SessionService,
    private matSnackBar: MatSnackBar,
    private deviceService: DeviceDetectorService){
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  get isItemActive(): boolean {
    if (this.item.isActive != null) {
      return this.item.isActive;
    }

    return true;
  }

  get isProductEditable(): boolean {
    if(this.isShowEditButton != null)
      return this.isShowEditButton;

    if (this.item.sourceType != null) {
      return (this.item.sourceType === SearchableProductSourceTypes.Default);
    }

    return true;
  }

  get isAdmin() : boolean {
    return this.sessionService.isAdmin;
  }

  get shouldShowSeperateVariantsOption(): boolean {
    return this.shouldShowSeperateVariants && this.sessionService.currentTenant?.settings?.provisioningOptions?.variantsSetupType == VariantsSetupType.VariantsAsProductChild &&
      this.item?.variantsSetupType != VariantsSetupType.ProductPerVariant && this.item?.parentId == null;
  }

  get ProductSku(): string {
    return this.item?.sku
  }

  get ProductTitle() : string{
    return this.item?.title;
  }

  get ProductBarcode(): string {
    return this.item?.barcode;
  }

  get ProductID(): string {
    return this.item?.id;
  }

  get isTrafficDataAvailable(): boolean {
    return this.isCompetitorsActions && this.item.trafficData;
  }

  get productNotes(): string {
    return this.item.note ? this.item.note : "Product Notes";
  }

  copyTitle(): void {
    this.matSnackBar.open(`Product's Title copied to clipboard`);
  }

  copySku(): void {
    this.matSnackBar.open(`Product's SKU copied to clipboard`);
  }

  copyBarcode(): void {
    this.matSnackBar.open(`Product's Barcode copied to clipboard`);
  }

  copyId() {
    this.matSnackBar.open(`Product's ID copied to clipboard`);
  }

  deleteItem(): void {
    this.deleteItemEmitter.emit(this.item.id);
  }

  emitItemForDetails(): void {
    this.emitItemForView.emit(this.item);
  }

  emitItemForPricingSuggestionDetails(): void {
    this.emitItemForPricingSuggestionView.emit(this.item);    
  }

  emitApplyItem(): void {
    this.emitItemForApply.emit(this.item);
  }

  emitRollbackItem(): void {
    this.emitItemForRollback.emit(this.item);
  }

  emitCompetitorUrlItem(): void {
    this.emitItemForRerun.emit(this.item);
  }

  emitCopyItem(): void {
    this.emitItemForCopy.emit(this.item);
  }

  separateVariants(): void {
    this.separateVariantsEmitter.emit(this.item);
  }

  emitItem(): void {
    this.editItemEmitter.emit(this.item);
  }

  emitItemAfterActivationStatusChange(activationStatus: boolean): void {
    this.item.isActive = !activationStatus;
    this.saveProduct.emit(this.item);
  }

  emitItemForDownload(): void {
    this.downloadItemEmitter.emit(this.item);
  }
  
  emitAdminButton(): void {
    this.emitAdminButtonClick.emit(this.item);
  }

  changeActivationProductStatus(isActive: boolean): void {
    this.item.isActive = !isActive;
  }

  onCancelYesNoDialog(): void {
    this.shouldShowDeleteProductText = false;
  }
  
  onCancelApplyDialog(): void {
    this.shouldShowApplyModal = false;
  }

  onConfirmYesNoDialog(): void {
    this.deleteItem();
    this.shouldShowDeleteProductText = false;
  }

  onCofirmApplyDialog(): void {
    this.emitApplyItem();
    this.shouldShowApplyModal = false;
  }

  onCofirmRollbackDialog(): void {
    this.emitRollbackItem();
    this.shouldShowRollbackModal = false;
  }

  onCancelRollbackDialog(): void {
    this.shouldShowRollbackModal = false;
  }

  showApplyModal() : void {
    if (!this.sessionService.isDemoPlatform) {
      this.shouldShowApplyModal = true;
    }
  }

  showRollbackModal() : void {
    if (!this.sessionService.isDemoPlatform) {
      this.shouldShowRollbackModal = true;
    }
  }

  showDeleteYesNoDialog(): void {
    if (!this.sessionService.isDemoPlatform) {
      this.shouldShowDeleteProductText = true;
    }
  }

  showCopyModal() : void {
    if (!this.sessionService.isDemoPlatform) {
      this.shouldShowCopyModal = true;
    }
  }

  showMetafields() {
    const ProductMetafields = this.getProductMetafields(); // Assume this method fetches your metafields

    this.matSnackBar.open(JSON.stringify(ProductMetafields), 'Close', {
      duration: 120000, // Keep the snack bar open for 1 minute
      // Optional: Add here any additional configuration you might want
    });
  }

  getProductMetafields() {
    return this.item?.metafields;
  }

  emitItemForUpdateCompetitors(): void {
    this.emitItemForUpdateCompetitorsEmitter.emit(this.item);
  }

  get isShopifyIntegration(): boolean {
    return this.sessionService?.currentIntegration?.integrationType == IntegrationTypes.Shopify;
  }

  showRerunModal() : void {
    this.shouldShowRerunCompetitorUrlSearchModal = true;
  }

  onConfirmRerunModalDialog(): void {
    this.emitCompetitorUrlItem();
  }

  onCancelRerunModalDialog(): void {
    this.shouldShowRerunCompetitorUrlSearchModal = false;
  }

  onConfirmCopyDialog(): void {
    this.emitCopyItem();
    this.shouldShowCopyModal = false;
  }

  onCancelCopyDialog(): void {
    this.shouldShowCopyModal = false;
  }
}
