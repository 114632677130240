import { Injectable } from '@angular/core';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class EpLoadingService {

  public get isPageLoading(): boolean {
    return this._isPageLoading;
  }
  public set isPageLoading(value: boolean) {
    this._isPageLoading = value;
  }
  public get isWaitingResponse(): boolean {
    return this._isWaitingResponse;
  }
  public set isWaitingResponse(value: boolean) {
    this._isWaitingResponse = value;
  }

  private _isPageLoading;
  private _isWaitingResponse;

  constructor(
    private matSnackBar: MatSnackBar,
  ) {
    this._isPageLoading = false;
    this._isWaitingResponse = false;
  }
  start(): void { this.isPageLoading = true; }

  public stop(message?: string): void;
  public stop(message?: string, action: string = null, config: MatSnackBarConfig = { duration: 2000 }): void {
    this.isPageLoading = false;
    if (message && typeof message === 'string') {
      this.matSnackBar.open(message, action, config);
    }
  }

  public print(message : string, config: MatSnackBarConfig = { duration: 2000 }) : void {
    if (message && typeof message === 'string') {
      this.matSnackBar.open(message, null, config);
    }
  }

  public printWithUndefinedDuration(message: string, config: MatSnackBarConfig = { duration: undefined }) : void {
    if (message && typeof message === 'string') {
      this.matSnackBar.open(message, null, config);
    }
  }

  public dismiss() : void {
    this.matSnackBar.dismiss();
  }
}
