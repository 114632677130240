import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pricing-plan-upgrade-dialog',
  templateUrl: './pricing-plan-upgrade-dialog.component.html',
  styleUrls: ['./pricing-plan-upgrade-dialog.component.scss']
})
export class PricingPlanUpgradeDialogComponent implements OnInit {
  dialogMaxWidth: number = 700;
  shouldShow: boolean = false;
  title: string = "Congratulations on Your Upgraded Plan! 🎉";
  isBackDropStatic: boolean = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.initPricingPlanUpgradeDialog();
  }

  close() {
    this.shouldShow = false;
  }

  private initPricingPlanUpgradeDialog() {
    let upgradeCompleted = this.route.snapshot.queryParamMap.get("upgradecompleted");
    if (upgradeCompleted && upgradeCompleted.toLowerCase() == "true")
      this.shouldShow = true;
  }
}
