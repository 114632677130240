<pm-ep-dialog [size]="dialogMaxWidth"
              [(shouldShow)]="shouldShow"
              [title]="title"
              [isPricingStrategy]="isPricingStrategy"
              [isPricingDiscount]="isPricingDiscount"
              [footerTemplate]="formDialogFooterTemplate"
              [isBackDropStatic]="isBackDropStatic"
              (dialogClose)="onDialogClose()">
  <ng-content></ng-content>
</pm-ep-dialog>

<ng-template #formDialogFooterTemplate>
  <div class="gw-flex-container">
    <div class="gw-flex-item">
      <button mat-button
              color="primary"
              type="submit"
              [disabled]="sendButtonDisabled"
              class="gw-margin-right-large">{{submitBtnTitle}}</button>
      <button mat-button
              color="$text-color"
              type="button"
              (click)="onDialogCancel()">{{cancelBtnTitle}}</button>
    </div>
  </div>
</ng-template>
