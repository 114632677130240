import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { dialogAnimation } from './../dialog.animations';
@Component({
  selector: 'pm-ep-dialog',
  templateUrl: './ep-dialog.component.html',
  styleUrls: ['./ep-dialog.component.scss'],
  animations: [dialogAnimation]
})
export class EpDialogComponent implements OnInit {
  @Input() isOnBoarding = false;
  @Input() isPricingStrategy = false;
  @Input() isPricingDiscount = false;
  @Input() isPricingPlanUpgrade = false;
  @Input() title: string = null;
  @Input() closeBtnTitle = 'CloseDialogBtn';
  @Input() hasControlsSection = true;
  @Input() footerTemplate: TemplateRef<any> = null;
  @Input() hasXButton = false;
  @Input() size: number = null;
  @Input() fullSize = false;
  @Input() smallSize = false;
  @Input() letParentControlHide = false;
  @Input() popupSectionCssClass = '';
  @Input() iconName: string = null;
  @Input() isPermanent: boolean = false;
  @Input() isBackDropStatic: boolean = false;
  @Input() set shouldShow(value: boolean) {
    this._shouldShow = value;
    this.shouldShowChange.emit(this._shouldShow);
  }
  @Output() shouldShowChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dialogClose: EventEmitter<any> = new EventEmitter();
  constructor() { }

  private _shouldShow = false;

  ngOnInit(): void { }

  onDialogClose(): void {
    if (!this.letParentControlHide && !this.isPermanent) {
      this.shouldShow = false;
    }

    this.dialogClose.emit();
  }
  onBackgroundClick(e): void {
    if (e.which !== 1) { return; }
    if (!this.isBackDropStatic)
      this.onDialogClose();
  }
  // tslint:disable-next-line: adjacent-overload-signatures
  get shouldShow(): boolean {
    return this._shouldShow;
  }

}
