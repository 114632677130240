import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import icSearch from '@iconify/icons-ic/twotone-search';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import { TableColumn } from 'app/common/interfaces/table-column.interface';

@Component({
  selector: 'search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss']
})
export class SearchHeaderComponent implements OnInit {

  searchCtrl = new FormControl();

  icSearch = icSearch;
  icFilterList = icFilterList;

  @Input()
  columns: TableColumn[]

  @Output()
  searchAction: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  search() {
    if (!this.searchCtrl.value) {
      return;
    }

    let keyword = this.searchCtrl.value.trim();
    this.searchAction.emit(keyword);
  }
}
