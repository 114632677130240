
enum AbnormalProducts {
  OverPriced = 0,
  UnderPriced = 1,
  EqualTo = 2,
  NoCompetition = 3,
  CheaperThanAverage = 5,
  HigherThanAverage = 6,  
  Verified = 7,
  UnVerified = 8,
  CompetitionFound = 9,
  UnCertainVerification = 10,
  MoreProductsToVerify = 11,
  MoreProductsToVerifyWithoutCompetition = 12,
  HasCompetitorUrls = 13,
  NoCompetitorUrls = 14,
  CheaperThanLowest = 15,
  HigherThanLowest = 16,
  EqualToLowest = 17,
  NotVisible = 18,
  Visible = 19,
  NotCompetitive = 20,
  Competitive = 21
}

const abnormalFiltersDict = [
  { title: "Over Priced", value: AbnormalProducts.OverPriced },
  { title: "Under Priced", value: AbnormalProducts.UnderPriced },
  { title: "No Competition", value: AbnormalProducts.NoCompetition },  
  { title: "Equal To Average", value: AbnormalProducts.EqualTo },
  { title: "Cheaper Than Average", value: AbnormalProducts.CheaperThanAverage },
  { title: "Higher Than Average", value: AbnormalProducts.HigherThanAverage },
  { title: "Cheaper Than Lowest", value: AbnormalProducts.CheaperThanLowest },
  { title: "Higher Than Lowest", value: AbnormalProducts.HigherThanLowest },
  { title: "Equal To Lowest", value: AbnormalProducts.EqualToLowest },
  { title: "Competition Found", value: AbnormalProducts.CompetitionFound }, 
  { title: "Not Visible", value: AbnormalProducts.NotVisible },
  { title: "Visible", value: AbnormalProducts.Visible },   
  { title: "Not Competitive", value: AbnormalProducts.NotCompetitive },   
  { title: "Competitive", value: AbnormalProducts.Competitive },   
  { title: "Verified Products", value: AbnormalProducts.Verified, onlyAdmin: true },  
  { title: "Unverified Products", value: AbnormalProducts.UnVerified, onlyAdmin: true },  
  { title: "Uncertain Verfication Products", value: AbnormalProducts.UnCertainVerification, onlyAdmin: true },    
  { title: "More Products To Verify", value: AbnormalProducts.MoreProductsToVerify, onlyAdmin: true },  
  { title: "More Products To Verify Without Competition", value: AbnormalProducts.MoreProductsToVerifyWithoutCompetition, onlyAdmin: true },  
  { title: "Has competitor URLs", value: AbnormalProducts.HasCompetitorUrls, onlyAdmin: true },  
  { title: "No Competitor URLs", value: AbnormalProducts.NoCompetitorUrls, onlyAdmin: true }  
];

export {
  AbnormalProducts,
  abnormalFiltersDict
}
