<div #containerRef>
  <ngx-charts-bar-vertical [view]="isTrafficEngagement ? [300, height] : [elementRefWidth, height]"
    [scheme]="colorScheme" [results]="data" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
    [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel" [showGridLines]="showGridLines" [legendPosition]="legendPositon"
    [legendTitle]="legendTitle" [barPadding]="barPadding" (window:resize)="onResize($event)"
    (select)="onSelect($event.name)" [yAxisTickFormatting]="yAxisTickFormatting">
    <ng-container *ngIf="isTrafficEngagement">
      <ng-template #tooltipTemplate let-model="model">
        <div style="margin: 10px;">
          <span>{{ model.name }}</span>
          <br />
          <span>{{ model.value }}K</span>
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="isMarketingChannelsDistribution">
      <ng-template #tooltipTemplate let-model="model">
        <div style="margin: 10px;">
          <span>{{ model.name }}</span>
          <br />
          <span>{{ model.value }}%</span>
        </div>
      </ng-template>
    </ng-container>
  </ngx-charts-bar-vertical>
</div>
