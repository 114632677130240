import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PricingEntityType } from '../enums/pricing-entity-type.enum';
import { IPricingCondition } from '../interfaces/conditions/pricing/IPricing-Condition';
import { IReport } from '../interfaces/IReport';
import { EpApiService } from './api/ep-api.service';
import { EpLoadingService } from './ep-loading.service';
import { saveAs as importedSaveAs } from 'file-saver';
import * as moment from 'moment';
import { ReportFileType } from '../enums/ReportFileType.enum';
import { ReportType } from '../enums/ReportType';
import { ISearchableProductsFilters } from '../interfaces/ISearchableProductsFilters';

const InterChangeSentencePlaceholder = " {{InterSentence}} ";
const productsOpeningSentence = "I would like to receive a daily report on ";
const listOpeningSentence = "I would like to receive a daily report on products that belong to specific ";
const interChangeProductsSentence = "that are at least";
const interChangeListSentence = ", and";
@Injectable({
  providedIn: 'root'
})

export class ReportsService {

  reports: IReport[] = [];
  reportsListChangedEmitter = new EventEmitter<Object>();

  constructor(private epApiService: EpApiService,
    private epLoadingService: EpLoadingService) { }

  getOpeningSentenceDescription(pricingEntityType: PricingEntityType): string {
    return pricingEntityType === PricingEntityType.Products ||  pricingEntityType === PricingEntityType.AllProducts ? productsOpeningSentence : listOpeningSentence;
  }

  getInterChangeSentenceDescription(pricingEntityType: PricingEntityType): string {
    return pricingEntityType === PricingEntityType.Products || pricingEntityType == PricingEntityType.AllProducts ? " " + interChangeProductsSentence : interChangeListSentence;
  }

  loadReports(): void {
    this.getReports().subscribe();
  }

  getReports(): Observable<IReport[]> {
    return this.epApiService.get<IReport[]>("Reports").pipe(
      tap(data => {
        this.reports = data;    
        this.reportsListChangedEmitter.emit();
        return; 
      }),
      catchError(this.handleError)
    );
  }

  createReport(report: IReport): Observable<IReport> {
    this.epLoadingService.start();
    return this.epApiService.post<IReport>('Reports', report).pipe(
      tap(data => { this.epLoadingService.stop("Report Created Successfully"); return; }),
      catchError(this.handleError)
    );
  }

  updateReport(report: IReport): Observable<IReport> {
    this.epLoadingService.start();
    return this.epApiService.patch<IReport>(`Reports/${report.id}`, report).pipe(tap(() => {
      this.epLoadingService.stop("Report Updated Successfully");
    }));
  }

  deleteReport(idToDelete: string): Observable<any> {
    this.epLoadingService.start();
    return this.epApiService.delete<IReport>(`Reports/${idToDelete}`).pipe(tap(() => {
      this.epLoadingService.stop("Report Deleted Successfully");
    }));
  }

  downloadReport(report: IReport): void {
    this.epLoadingService.start();
    this.epApiService.downloadFileWithPost(`Reports/GenerateReportFile`, { reportId: report.id, fileType: ReportFileType.CSV }).subscribe(blob => {
      importedSaveAs(blob, `${report.name}-${moment().format('YYYY-MM-DD_HH-mm')}.csv`);
      this.epLoadingService.stop("Downloaded Successfully");
    });
  }

  exportFileByReport(report: IReport): void {
    this.epLoadingService.start();
    this.epLoadingService.printWithUndefinedDuration("The report will be downloaded in the background - you can keep working in the meantime");
    this.epApiService.downloadFileWithPost(`Reports/ExportFileByReport`,
      {
        "report": { 
          name: report.name,
          type: report.type,
          fileType: report.fileType,
          entity: report.entity,
          specificIds: report.specificIds,
          columns: report.columns,
          filters : report.filters
        }
      }).subscribe(blob => {
        importedSaveAs(blob, `${report.name}-${moment().format('YYYY-MM-DD_HH-mm')}.csv`);
        this.epLoadingService.stop("Exported Successfully");
      },
        err => this.epLoadingService.stop("Something went wrong in exporting data! Please try again later.")
      );
  }

  getReportDescription(condition: IPricingCondition): string {
    let pricingEntityType: PricingEntityType = condition.targetComponent.entityType;
    return this.getOpeningSentenceDescription(pricingEntityType)
      + condition.description?.replace(InterChangeSentencePlaceholder, this.getInterChangeSentenceDescription(pricingEntityType))
  }

  exportProductsReport(columns : string[], filters : ISearchableProductsFilters, specificIds : string[]){
    let columnsToExports = columns.filter(x =>
      x === "title" || x === "sku" || x === "brand" || x === "price" || x === "cost" || x === "barcode" || x === "externalId" || x === "volume"
    )?.slice();

    if(columns.indexOf("competitors") > -1){
      columnsToExports.push("cheapest price");
      columnsToExports.push("average price");
      columnsToExports.push("highest price");
      columnsToExports.push("cheapest competitor");      
      columnsToExports.push("highest competitor");      
      columnsToExports.push("competitors count");      
      columnsToExports.push("cheapest competitor url");
    }

    if(columns.indexOf("image") > -1){
      columnsToExports.push("imageUrl");
    }

    let report: IReport = {
      id: null,
      name: "products report: " + new Date().toISOString(),
      type: ReportType.Entity,
      condition: null,
      columns: columnsToExports,
      emailSubscribers: [],
      description: null,
      fileType: ReportFileType.CSV,
      isActive: null,
      entity: "searchableProducts",
      filters,
      specificIds
    };

    this.exportFileByReport(report);
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error has occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status},error message is : ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
