enum VariantsSetupType {
  ProductPerVariant = 0,
  VariantsAsProductChild = 1
}

const variantsSetupOptionsArray = [
  {
    title : 'Find competitors for parent products only' , value : VariantsSetupType.VariantsAsProductChild
  },
  {
    title : 'Find competitors for all of the variants' , value : VariantsSetupType.ProductPerVariant
  }
];

export {
  VariantsSetupType,
  variantsSetupOptionsArray
}