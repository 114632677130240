// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import * as authConfig from '../../auth_config.json';

let config = authConfig;

const baseApiUrl = "https://dev.api.intelis.ai/";

export const environment = {
  production: false,
  auth: {
    domain: config.domain,
    clientId: config.clientId,
    audience: config.audience,
    connection: config.connection,
    redirectUri: window.location.origin,
  },
  rollbarToken : "a3566f3ea4134aa2b230469ab642c404",
  apiUrl : baseApiUrl + "api/v1/",
  baseApiUrl,
  secretPhrase : "a1c38438-9f13-4a8d-b73e-0dcf2db9df39",
  secretSalt : "QW9fZnp4K1plbGhDYXZwWg==",
  intelisSSoTokenName : 'intelis-sso-token',
  httpInterceptor: {
    allowedList: [`${config.apiUri}/*`],
  },
  demo: {
    user : config.demoUser,
    password : config.demoPassword
  }  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
