import { PricingPlanTypes } from "app/common/enums/billing/PricingPlanTypes.enum";
import { IPricingPlan } from "app/common/interfaces/billing/pricingPlan";

export const pricingPlans: IPricingPlan[] = [
  {
    name: "Basic",
    actionMessage: "Change Plan",
    priceInformation: "per {0}, up to 100 products",
    pricingPlanType: PricingPlanTypes.Basic,
    isCurrent: false,
    productsLimit: 100,
    price : 49,
    annualPrice : 588
  },
  {
    name: "Premium",
    actionMessage: "Change Plan",
    priceInformation: "per {0}, up to 1000 products",
    pricingPlanType: PricingPlanTypes.Premium,
    isCurrent: false,
    productsLimit: 1000,
    price : 149,
    annualPrice : 1788
  },
  {
    name: "Business",
    actionMessage: "Change Plan",
    priceInformation: "per {0}, up to 5000 products",
    pricingPlanType: PricingPlanTypes.Business,
    isCurrent: false,
    productsLimit: 5000,
    price: 349,
    annualPrice : 4188
  },
  {
    name: "Enterprise",
    actionMessage: "Change Plan",
    priceInformation: "per {0}, up to 20000 products",
    pricingPlanType: PricingPlanTypes.Enterprise,
    isCurrent: false,
    productsLimit: 20000,
    price: 749,
    annualPrice : 7490
  },
  {
    name: "Custom",
    actionMessage: "Contact Us",
    priceInformation: "Build your own plan",
    pricingPlanType: PricingPlanTypes.Custom,
    isCurrent: false,
    productsLimit: 100000
  }
];
