import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";

const handleError = (err: HttpErrorResponse) => {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error has occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status},error message is : ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
}

export {
    handleError
}