import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EpFormDialogComponent } from 'app/common/dialogs/ep-form-dialog/ep-form-dialog.component';
import { ICompetitorUrl } from 'app/common/interfaces/competitors/ICompetitorUrl';
import { CompetitorsService } from 'app/common/services/competitors.service';


@Component({
  selector: 'delete-competitor-url-dialog',
  templateUrl: './delete-competitor-url-dialog.component.html',
  styleUrls: ['./delete-competitor-url-dialog.component.scss']
})
export class DeleteCompetitorUrlComponent implements OnInit {

  @Output()
  onDialogCloseEvent: EventEmitter<Object> = new EventEmitter<Object>();

  @Output()
  onFinishEvent: EventEmitter<Object> = new EventEmitter<Object>();

  @ViewChild(EpFormDialogComponent) epFormDialogComponent: EpFormDialogComponent;

  constructor(private competitorService: CompetitorsService) {    
  }

  get title() : string{    
    return `Confirm url deletion`;
  }

  @Input()
  shouldShowDialog: boolean;

  @Input()
  targetCompetitorUrl: ICompetitorUrl;

  deleteCompetitorUrlForm = new FormGroup({
  });

  isSubmitButtonDisabled() {
    return false;
  }

  onDialogClose() {
    this.onDialogCloseEvent.emit();
  }

  onSubmit() {    
    this.competitorService.removeCompetitorUrlAsync(this.targetCompetitorUrl.id).subscribe(() => {
      this.onFinishEvent.emit();
      this.onDialogClose();
    });
  }

  ngOnInit(): void {
  }

}
