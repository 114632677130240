<div *ngIf="isShowProductsLimit()">
  <nb-progress-bar [value]="progress" status="warning" [displayValue]="false" [size]="'tiny'">
  </nb-progress-bar>
  <div>
    <p class="products-amount" href="javascript:void(0);" (click)="navigateToBilling()">
      Only {{syncedProductsCount}}/{{allProductsCount}} of your
      products are synced
    </p>
    <a href="javascript:void(0);" (click)="navigateToBilling()" class="billing-link"> Upgrade now </a>
  </div>
</div>
