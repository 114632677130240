
import { Component, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'pm-number-card-chart',
  templateUrl: './number-card-chart.component.html',
  styleUrls: ['./number-card-chart.component.scss']
})
export class NumberCardChartComponent implements AfterViewInit {
  multi: any[];
  view: any[] = null;
  cardColor = '#ffffff';
    
  @Input() data;
  @Input() colorScheme: {
    domain: string[];
  } = { domain: [] };
  @Input() height = 400;
  @ViewChild('containerRef') containerRef: ElementRef;


  onResize(event): void {
    if(this.elementRefWidth){
      this.view = [this.elementRefWidth * 0.9, this.height];
    }
  }

  ngAfterViewInit() {
    window.dispatchEvent(new Event('resize'));
  }

  get elementRefWidth(): number {
    return this.containerRef?.nativeElement?.offsetWidth;
  }
}

