import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IPricingCondition } from 'app/common/interfaces/conditions/pricing/IPricing-Condition';
import { IOption } from 'app/common/interfaces/IOption';
import { ISearchableProduct } from 'app/common/interfaces/ISearchableProduct';
import { ProductsService } from 'app/common/services/product.service';
import { RulesService } from 'app/common/services/rules.service';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, filter, map, takeUntil, tap } from 'rxjs/operators';
import * as $ from "jquery";

@Component({
  selector: 'pricing-exclusions',
  templateUrl: './pricing-exclusions.component.html',
  styleUrls: ['./pricing-exclusions.component.scss']
})
export class PricingExclusionsComponent implements OnInit, OnDestroy {

  excludeProductsSentence: string = "Exclude the following products";
  relatedExcludeProducts: ISearchableProduct[] = [];
  excludeProductsSubscription: Subscription;
  excludeProductsPlaceholderLabel: string;
  excludeProductsNoEntriesFoundLabel: string;
  selectedExcludedProducts: IOption[] = [];
  isExcludeProductsDisabled: boolean = true;
  excludedProductsFilterSubscription: Subscription;
  searching: boolean;
  searchTermForProducts: string = "";

  currentCondition: IPricingCondition = null;
  selectedOption: string;

  public excludeProductsEntityIdsMultiFilterCtrl: FormControl = new FormControl([]);
  public shouldAddExcludeProducts: FormControl = new FormControl(false);

  /** list of exclude products entities filtered by search keyword */
  public filteredExcludedProductsEntitiesMulti: ReplaySubject<IOption[]> = new ReplaySubject<IOption[]>(1);
  
  protected _onDestroy = new Subject<void>();

  constructor(private rulesService: RulesService,
    private productService: ProductsService
  ) {
  }
  
  ngOnInit(): void {
    this.initialize();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initialize() {
    this.initExcludeProducts();
  }

  get selectedExcludedProductsToSave(): string[] {
    if (this.isExcludeProductsDisabled !== null && this.isExcludeProductsDisabled !== undefined) {
      if (!this.isExcludeProductsDisabled)
        return (this.selectedExcludedProducts ?? []).map(x => x.value);
    }
    return [];
  }

  initializeExcludeProducts(condition: IPricingCondition) {
    this.currentCondition = condition;
    this.loadExcludeProductsEntityIds();
    this.selectedOption = this.selectedExcludedProducts[0]?.value;
  }

  loadExcludeProductsEntityIds(): void {
    this.excludeProductsPlaceholderLabel = "Find product...";
    this.excludeProductsNoEntriesFoundLabel = "No matching product found!";
    this.setSelectedExcludedProducts();
    if (this.selectedExcludedProducts && this.selectedExcludedProducts.length > 0) {
      this.shouldAddExcludeProducts.setValue(true);
      this.setShouldAddExcludeProducts(true);
    } else {
      this.shouldAddExcludeProducts.setValue(false);
      this.setShouldAddExcludeProducts(false);
    }
  }

  //this is terrible but unfortunately the only way it works
  setShouldAddExcludeProducts(event: any) {
    let eventValue = event.checked != null ? event.checked : event;
    if (eventValue == true)
      this.isExcludeProductsDisabled = false;
    else
      this.isExcludeProductsDisabled = true;
    this.setupExcludedProductsSubscription();
    if (event.checked != null)
      this.setSelectedExcludedProducts();
  }

  setupExcludedProductsSubscription() {
    if (this.isExcludeProductsDisabled !== null && this.isExcludeProductsDisabled !== undefined) {
      if (!this.isExcludeProductsDisabled) {
        this.excludedProductsFilterSubscription = this.excludeProductsEntityIdsMultiFilterCtrl?.valueChanges
          .pipe(
            filter(search => !!search),
            tap(() => this.searching = true),
            takeUntil(this._onDestroy),
            debounceTime(500),
            map(search => this.searchEntities(search)),
            delay(500),
            takeUntil(this._onDestroy)
          )
          .subscribe(response => {
            this.handleSearchResponse(response, this.filteredExcludedProductsEntitiesMulti);
          }, error => {
            this.searching = false;
            this.filteredExcludedProductsEntitiesMulti.next([]);
            // Handle error
          });
      } else
        this.unsubscribe();
    } else
      this.unsubscribe();
  }

  async searchEntities(search: string) {
    if (this.searchTermForProducts === search)
      return { isMoveForward: false, filteredEntities: [], searchTerm: search };
    this.searchTermForProducts = search;
    let productsResponse = await this.productService.filterProductsFromApi({ filters: { searchTerm: search } });
    return { isMoveForward: true, filteredEntities: productsResponse.products, searchTerm: search };
  }

  async handleSearchResponse(response, filteredEntitiesSubject: ReplaySubject<IOption[]>) {
    this.searching = false;
    let results = await response;
    if (!results.isMoveForward) return;
    let filteredEntitiesDataSource: IOption[] = [];
    let data = results.filteredEntities;
    if (data && data.length > 0) {
      let searchResults = data.map(x => ({ title: x.title, value: x.id }));
      searchResults.forEach(item => {
        if (!filteredEntitiesDataSource.some(x => x.value === item.value))
          filteredEntitiesDataSource.push(item);
      });
      let targetFilteredProducts = filteredEntitiesDataSource.slice();
      // Use a Set to store unique values
      const uniqueValues = new Set<any>();
      const distinctTargetFilteredProducts: IOption[] = [];
      targetFilteredProducts.forEach((option) => {
        if (!uniqueValues.has(option.value)) {
          uniqueValues.add(option.value);
          distinctTargetFilteredProducts.push(option);
        }
      });
      filteredEntitiesSubject.next(distinctTargetFilteredProducts);
    } else
      filteredEntitiesSubject.next([]); 
  }

  onExcludedEntityChange(event: any, entity: any): void {
    if (event.checked) {
      if (!this.selectedExcludedProducts.some(x => x.value === entity.value))
        this.selectedExcludedProducts.push(entity);
    } else
      this.selectedExcludedProducts = this.selectedExcludedProducts.filter(x => x.value !== entity.value);
    this.updateFilteredExcludedProductsEntitiesMulti();
  }

  removeExcludedSelectedProduct(product: any) {
    this.selectedExcludedProducts = this.selectedExcludedProducts.filter(x => x.value !== product.value);
    this.updateFilteredExcludedProductsEntitiesMulti();
  }

  isProductSelected(entity: any): boolean {
    return this.selectedExcludedProducts.some(x => x.value === entity.value);
  }

  clearExcludeProductsSearch() {
    $("#exclude-products-search-input").children(".mat-select-search-inner").children(".mat-select-search-input").val("");
    $("#exclude-products-search-input").children(".mat-select-search-inner").children(".mat-select-search-clear").hide();
    this.searchTermForProducts = "";
  }

  setSelectedExcludedProducts(): void {
    this.selectedExcludedProducts = [];
    if (this.currentCondition) {
      if (this.currentCondition.targetComponent?.excludedProductIds != null && this.currentCondition.targetComponent?.excludedProductIds?.length > 0) {
        if (this.relatedExcludeProducts && this.relatedExcludeProducts.length > 0) {
          let relatedExcludeProducts = [...this.relatedExcludeProducts];
          for (let excludedProductId of this.currentCondition.targetComponent.excludedProductIds) {
            let rulesExcludedProduct = relatedExcludeProducts.filter(x => x.id == excludedProductId)[0];
            if (rulesExcludedProduct) {
              let selectedExcludedProduct: IOption = { title: rulesExcludedProduct.title, value: rulesExcludedProduct.id }; 
              this.selectedExcludedProducts.push(selectedExcludedProduct);
            }
          }
        }
      }
    }
    this.updateFilteredExcludedProductsEntitiesMulti();
  }

  updateFilteredExcludedProductsEntitiesMulti(): void {
    if (this.selectedExcludedProducts && this.selectedExcludedProducts.length > 0)
      this.filteredExcludedProductsEntitiesMulti.next(this.selectedExcludedProducts);
    else
      this.filteredExcludedProductsEntitiesMulti.next([]);
    this.selectedOption = this.selectedExcludedProducts[0]?.value;
  }

  get excludedProductsLabel(): string {
    let label = "";
    if (this.isExcludeProductsDisabled !== null && this.isExcludeProductsDisabled !== undefined) {
      if (!this.isExcludeProductsDisabled) {
        if (this.selectedExcludedProducts && this.selectedExcludedProducts.length > 0) {
          label = this.selectedExcludedProducts[0].title;
          if (this.selectedExcludedProducts.length > 1) {
            label += " (+" + (this.selectedExcludedProducts.length - 1);
            if (this.selectedExcludedProducts.length === 2)
                label += " other";
            else
                label += " others";
            label +=  ")";
          }
        }
      }
    }
    return label;
  }

  private initExcludeProducts(): void {
    this.loadExcludeProducts();
    this.excludeProductsSubscription = this.rulesService.ruleListChangedEmitter.subscribe(() => {
      this.loadExcludeProducts();
    });
  }

  private loadExcludeProducts(): void {
    this.relatedExcludeProducts = this.rulesService.rulesExcludedProducts;
  }

  private unsubscribe() {
    if (!this.excludeProductsSubscription?.closed)
      this.excludeProductsSubscription?.unsubscribe();
    if (!this.excludedProductsFilterSubscription?.closed)
      this.excludedProductsFilterSubscription?.unsubscribe();
  }
}
