import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'multi-select-chips',
  templateUrl: './multi-select-chips.component.html',
  styleUrls: ['./multi-select-chips.component.scss']
})
export class MultiSelectChipsComponent {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  itemCtrl = new FormControl();
  filteredItems: Observable<any[]>;

  @Input() placeholder = "New Filter..."
  @Input() allItems: any[] = [];
  @Input() items: any[] = [];
  @Input() title: string = "items";
  @Input() maxSelections: number = 3;
  @Input() filterAttribute: string;
  @Input() isDisableAbnormalFilter: boolean;
  @Output() addItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeItem: EventEmitter<number> = new EventEmitter<number>();

  @ContentChild('optionTemplate') optionTemplate: TemplateRef<any>;

  @ViewChild('ItemInput') itemInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor() {
    this.filteredItems = this.itemCtrl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => {
        return this._filter(item);
      }));
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      if (input) {
        input.value = '';
      }
    }
  }

  remove(item: any): void {
    const index = this.items.findIndex(x => item[this.filterAttribute] == x[this.filterAttribute]);

    if (index >= 0) {
      this.items.splice(index, 1);
      this.removeItem.emit(item);
      this.itemCtrl.setValue(this.itemCtrl.value);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let item: any = this.getItem(event.option.value);
    this.items.push(item);
    this.addItem.emit(item);
    this.itemInput.nativeElement.value = '';
    this.itemCtrl.setValue(null);
  }

  isMaxCompetitorsSelection(): boolean {
    return this.items?.length === this.maxSelections;
  }

  isRemovable(item: any) {
    return item.removable ?? this.removable;
  }

  private _filter(value: any): any[] {
    let filteredItems = this.allItems;
    if (this.isMaxCompetitorsSelection()) {
      return [];
    }
    filteredItems = filteredItems?.filter(item => this.items?.find(x => x[this.filterAttribute] == item[this.filterAttribute]) == null);

    if (value != null) {
      const filterValue = value.toLowerCase();
      filteredItems = filteredItems?.filter(item => item[this.filterAttribute]?.toLowerCase()?.indexOf(filterValue) === 0);
    }

    return filteredItems;
  }

  private getItem(title: string): any {
    return this.allItems?.filter(item => item[this.filterAttribute] === title)[0];
  }
}
