<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>    
    <a class="logo" href="#" (click)="navigateHome()"> <img class="app-logo"
      src="../../../../assets/images/intelis_logo.svg" alt="Logo" /></a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
  <!-- <ngx-layout-direction-switcher class="direction-switcher"></ngx-layout-direction-switcher> -->
</div>
<div class="progress-bar">
  <products-limit-alert class="margin-top--m"></products-limit-alert>
</div>
<div class="header-container">
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action> -->
    <!-- <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <div *ngIf="user != null">
        <nb-user [nbContextMenu]="userMenu" nbContextMenuTrigger="hover" [onlyPicture]="userPictureOnly"
          [name]="user?.name" [picture]="user?.picture">
        </nb-user>
      </div>
    </nb-action>
  </nb-actions>
</div>
