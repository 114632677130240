import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GreenColorScheme } from 'app/common/constants';
import { ISeriesItem } from 'app/common/interfaces/ISeriesItem';
import { ProductsService } from 'app/common/services/product.service';

@Component({
  selector: 'advanced-pie-chart',
  templateUrl: './advanced-pie-chart.component.html',
  styleUrls: ['./advanced-pie-chart.component.scss']
})
export class AdvancedPieChartComponent implements AfterViewInit, AfterViewChecked {

  multi: any[];
  view: any[] = null;
  @Input() height: number = 400;
  @Input() data: ISeriesItem[] = [];
  @Input() itemsData = {};

  gradient = false;

  @ViewChild('containerRef') containerRef: ElementRef;

  @Input() colorScheme = {
    ...GreenColorScheme
  };

  constructor(private productService: ProductsService,
    private router: Router,
    private cdf: ChangeDetectorRef) {
  }

  ngAfterViewChecked(): void {
    this.cdf.detectChanges();
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  onSelect(item): void {
    let value = this.itemsData[item.name.replace(/\s/g, "")];
    if (value) {
      this.productService.addAbnormalProductsFilters(value);
      this.router.navigate(['/pages/platform/products']);
    }
  }

  onResize(event): void {
    if (this.elementRefWidth) {
      this.view = [this.elementRefWidth, this.height];
    }
  }

  get elementRefWidth(): number {
    return this.containerRef?.nativeElement?.offsetWidth;
  }
}
