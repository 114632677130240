import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { GwRequestMethodType, IGwApiToken } from './gw-api.interface';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '../../../../environments/environment';
import { EncryptedLocalStorageService, GwLocalStorageService, StorageDict } from '../gw-local-storage.service';
import { SharedService } from '../shared.service';
import { access } from 'fs';

const BaseUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class EpApiService {

  authToken: string;
  baseUrl: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private localStorage: GwLocalStorageService,
    private encryptedLocalStorage : EncryptedLocalStorageService,
    private sharedService : SharedService) {

    this.baseUrl = BaseUrl;
  }

  async init(){
    let accessToken = this.encryptedLocalStorage.getAccessToken();

    if(accessToken == null){
      accessToken = this.getAuthTokenFromCookies();
    }

    if(this.authToken == null){    
      if (this.sharedService.isValidAccessToken(accessToken)) {
        this.authToken = "Bearer " + accessToken;
      }
      else {
        try{
          let token = await this.authService.getAccessTokenSilently().toPromise();
          this.authToken = "Bearer " + token;
        }        
        catch{        
          console.log("no access token found");
        }
      }
    }

    return this.authToken;
  }

  public getAuthTokenFromCookies() : string{
    return this.getCookie(environment.intelisSSoTokenName);
  }
  // HTTP METHODS
  /**
   * Performs HTTP GET request.
   */
  public get<TResponseData = any>(url: string, options?: any)
    : Observable<TResponseData> {
    return this.request<TResponseData>('GET', url, null, options).pipe(
      map((resp: TResponseData) => (resp)));
  }

  /**
   * Performs HTTP POST request.
   */
  public post<TResponseData = any>(url: string, body: any, options?: any)
    : Observable<TResponseData> {
    return this.request<TResponseData>('POST', url, body, options).pipe(
      map((resp: TResponseData) => (resp)));
  }

  /**
   * Performs HTTP PUT request.
   */
  public put<TResponseData = any>(url: string, body: any, options?: any)
    : Observable<TResponseData> {
    return this.request<TResponseData>('PUT', url, body, options).pipe(
      map((resp: TResponseData) => (resp)));
  }

  /**
   * Performs HTTP PATCH request.
   */
  public patch<TResponseData = any>(url: string, body: any, options?: any)
    : Observable<TResponseData> {
    return this.request<TResponseData>('PATCH', url, body, options).pipe(
      map((resp: TResponseData) => (resp)));
  }

  /**
   * Performs HTTP DELETE request.
   */
  public delete<TResponseData = any>(url: string, options?: any)
    : Observable<TResponseData> {
    return this.request<TResponseData>('DELETE', url, null, options).pipe(
      map((resp: TResponseData) => (resp)));
  }

  /**
   * Performs HTTP OPTIONS request.
   */
  public options<TResponseData = any>(url: string, options?: any)
    : Observable<TResponseData> {
    return this.request<TResponseData>('OPTIONS', url, null, options).pipe(
      map((resp: TResponseData) => (resp)));
  }

  /**
   * Performs HTTP HEAD request.
   */
  public head<TResponseData = any>(url: string, options?: any)
    : Observable<TResponseData> {
    return this.request<TResponseData>('HEAD', url, null, options).pipe(
      map((resp: TResponseData) => (resp)));
  }

  // ACCOUNT
  /**
   * Performs HTTP GET request.
   * Used for calls to Account controller (where the API response content IS NOT of type GwResponse).
   */
  public account_get<TResponseData = any>(url: string, options?: any)
    : Observable<TResponseData> {
    return this.request<TResponseData>('GET', url, null, options).pipe(
      map((resp: TResponseData) => resp));
  }

  /**
   * Performs HTTP POST request.
   * Used for calls to Account controller (where the API response content IS NOT of type GwResponse).
   */
  public account_post<TResponseData = any>(url: string, body: any, options?: any)
    : Observable<TResponseData> {
    return this.request<TResponseData>('POST', url, body, options).pipe(
      map((resp: TResponseData) => (resp)));
  }

  // TOKEN
  /**
   * Performs HTTP POST request to retrieve an Access Token.
   */
  public token(username: string, password: string, onlyAdminAllowed: boolean = false)
    : Observable<IGwApiToken> {
    const url = `${this.baseUrl}/token`;

    const body = new HttpParams()
      .append('grant_type', 'password')
      .append('username', encodeURIComponent(username))
      .append('password', encodeURIComponent(password));

    let headers = new HttpHeaders();
    headers = this.setDefaultHttpHeaders(headers);
    headers = this.setHttpContentTypeHeaderByBody(headers, body);
    headers = headers.append('onlyAdminAllowed', onlyAdminAllowed.toString());

    return this.httpClient.post<IGwApiToken>(url, body, { headers: headers }).pipe(
      catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

  /**
   * Performs HTTP POST request to exchange an External Access Token with an Access Token.
   */
  public tokenExchange(externalAccessToken: string, provider: string, syncedUserId: string)
    : Observable<IGwApiToken> {
    const url = `${this.baseUrl}/token`;

    const body = new HttpParams()
      .append('grant_type', 'urn:ietf:params:oauth:grant-type:token-exchange')
      .append('subject_token', externalAccessToken)
      .append('subject_token_type', 'urn:ietf:params:oauth:token-type:access_token')
      .append('provider', provider);

    let headers = new HttpHeaders();
    headers = this.setDefaultHttpHeaders(headers);
    headers = this.setHttpContentTypeHeaderByBody(headers, body);
    if (syncedUserId) {
      headers = headers.append('SyncedUserId', syncedUserId);
    }

    return this.httpClient.post<IGwApiToken>(url, body, { headers: headers }).pipe(
      catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

  // FILES
  /**
   * Performs HTTP POST request to upload a single file.
   */
  public uploadFile<TResponseData = any>(url: string, file: File)
    : Observable<any> {
    const body = new FormData();
    body.append('uploadFile', file, file?.name);

    return this.post<TResponseData>(url, body, null);
  }

  /**
   * Performs HTTP GET request to download a single file.
   */
  public downloadFile(url: string)
    : Observable<Blob> {
    let headers = new HttpHeaders();
    headers = this.setDefaultHttpHeaders(headers);
    return this.httpClient.get(url, {
      headers: headers,
      observe: 'body',
      responseType: 'blob'
    });
  }

  /**
* Performs HTTP POST request to download a single file.
*/
  public downloadFileWithPost(url: string, body: any)
    : Observable<Blob> {
    let headers = new HttpHeaders();
    headers = this.setDefaultHttpHeaders(headers);

    return this.post(url, body, {
      headers: headers,
      observe: 'body',
      responseType: 'blob'
    });
  }

  public getRequestOptions(options: any, body: any) {
    if (!options) {
      options = {};
    }
    options.headers = this.setDefaultHttpHeaders(options.headers);
    options.headers = this.setHttpContentTypeHeaderByBody(options.headers, body);
    options.observe = options.observe || 'body';
    return options;
  }

  private getRequestUrl(endpoint: string): string {
    return BaseUrl + endpoint;
  }

  /**
 * Performs any type of HTTP request.
 */
  private request<TResponseData>(method: GwRequestMethodType, endpoint: string, body?: any, options?: any)
    : Observable<TResponseData> {
    options = this.getRequestOptions(options, body);
    let endpointUrl: string = this.getRequestUrl(endpoint);
    return this._request<TResponseData>(method, endpointUrl, body, options).pipe(
      catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

  /**
  * Performs any type of HTTP request. Calling the Angular HttpClient service.
  */
  private _request<TResponseData = any>(method: GwRequestMethodType, url: string, body?: any, options?: { observe: 'body' })
    : Observable<TResponseData> {
    if (!!options.observe) {
      options.observe = 'body';
    }
    switch (method) {
      case 'GET':
        return this.httpClient.get<TResponseData>(url, options);

      case 'POST':
        return this.httpClient.post<TResponseData>(url, body, options);

      case 'PUT':
        return this.httpClient.put<TResponseData>(url, body, options);

      case 'PATCH':
        return this.httpClient.patch<TResponseData>(url, body, options);

      case 'DELETE':
        return this.httpClient.delete<TResponseData>(url, options);

      case 'OPTIONS':
        return this.httpClient.options<TResponseData>(url, options);

      case 'HEAD':
        return this.httpClient.head<TResponseData>(url, options);
    }
  }

  /**
 * Set default HTTP headers.
 * @param headers HTTP headers
 */
  private setDefaultHttpHeaders(headers: HttpHeaders)
    : HttpHeaders {
    headers = headers || new HttpHeaders();
    // if (this.hdrHorizontalId && !headers.has('HorizontalId')) {
    //   headers = headers.append('HorizontalId', this.hdrHorizontalId.toString());
    // }

    // if (this.hdrHorizontalName && !headers.has('HorizontalName')) {
    //   headers = headers.append('HorizontalName', this.hdrHorizontalName);
    // }

    // if (this.hdrSharedLanguageId && !headers.has('SharedLanguageId')) {
    //   headers = headers.append('SharedLanguageId', this.hdrSharedLanguageId.toString());
    // }

    // if (this.hdrAuthorization && !headers.has('Authorization')) {
    //   headers = headers.append('Authorization', this.hdrAuthorization);
    // }


    let overrideTenant = this.localStorage.get(StorageDict.overrideTenant);
    if (overrideTenant != null) {
      headers = headers.set(StorageDict.overrideTenant, overrideTenant);
    }

    if(this.authToken){
      headers = headers.set('Authorization', this.authToken);
    }
    headers = headers.set('Access-Control-Allow-Origin', '*');

    return headers;
  }

  /**
   * Set HTTP Content-Type header by the given body instance.
   * @param headers HTTP headers
   * @param body Body payload
   */
  private setHttpContentTypeHeaderByBody(headers: HttpHeaders, body: any)
    : HttpHeaders {
    headers = headers || new HttpHeaders();

    // Detects the Content-Type from the given body instance.
    if (body === null || body === undefined) {
      headers = headers.set('Content-Type', 'application/json');
    } else if (body instanceof HttpParams) {
      headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    } else if (body instanceof FormData) {
      return headers; // SKIP/DO NOT SET as this is set automatically as 'multipart/form-data; boundary=XXX'
    } else if (body instanceof Blob) {
      headers = headers.set('Content-Type', 'application/octet-stream');
    } else if (body instanceof ArrayBuffer) {
      headers = headers.set('Content-Type', 'application/octet-stream');
    } else if (body && typeof body === 'object') {
      headers = headers.set('Content-Type', 'application/json');
    } else if (body && typeof body === 'boolean') {
      headers = headers.set('Content-Type', 'application/json');
    } else {
      headers = headers.set('Content-Type', 'application/json');
    }

    return headers;
  }

  /**
 * Handle Access Token error response.
 * @param err Response error
 */
  public handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error has occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status},error message is : ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  // Utility function to get the value of a specific cookie by name
  public getCookie(name: string): string | null {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
      return match[2];
    }
    return null;
  }
}
