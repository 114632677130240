import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EpApiService } from '../../common/services/api/ep-api.service';
import { EpLoadingService } from '../../common/services/ep-loading.service';
import { tap } from 'rxjs/operators';
import { IIgnoreComparableProductRequest } from 'app/common/interfaces/requests/IIgnoreComparableProductRequest';
import { IIgnoreComparableProductsRequest } from 'app/common/interfaces/requests/IIgnoreComparableProductsRequest';
import { IAddVerifiedComparableProductsRequest } from 'app/common/interfaces/requests/AddVerifiedComparableProductsRequest';
import { IVerifiedComparableProduct } from '../interfaces/comparableProducts/IVerifiedComparableProduct';
import { IChangeVerificationStatusRequest } from 'app/common/interfaces/requests/IChangeVerificationStatusRequest';
import { ISearchableProduct } from '../interfaces/ISearchableProduct';
import { IProduct } from '../interfaces/IProduct';

@Injectable({
  providedIn: 'root'
})
export class ComparableProductsService {

  constructor(
    private epApiService: EpApiService,
    private epLoadingService: EpLoadingService) {
  }

  ignoreComparableProduct(ignoredComparableProductRequest: IIgnoreComparableProductRequest): Observable<ISearchableProduct> {
    this.epLoadingService.start();
    return this.epApiService.post(`ComparableProducts/IgnoreComparableProduct`, ignoredComparableProductRequest).pipe(tap(() => {
      this.epLoadingService.stop("URL deleted - may take up to 24 hours to sync");
    }));
  }

  ignoreComparableProducts(ignoredComparableProductsRequest: IIgnoreComparableProductsRequest): Observable<ISearchableProduct> {
    this.epLoadingService.start();
    return this.epApiService.post(`ComparableProducts/IgnoreComparableProducts`, ignoredComparableProductsRequest).pipe(tap(() => {
      this.epLoadingService.stop("URL deleted - may take up to 24 hours to sync");
    }));
  }


  verifyComparableProduct(verifiedComparableProductRequest: IAddVerifiedComparableProductsRequest): Observable<ISearchableProduct> {
    this.epLoadingService.start();
    return this.epApiService.post(`ComparableProducts/VerifySearchableProduct`, verifiedComparableProductRequest).pipe(tap(() => {
      this.epLoadingService.stop("product verification status changed");
    }, err => {
      this.epLoadingService.stop("product verification status failed to change");
    }));
  }

  changeVerificationStatus(searchableProductId: string, isVerified: boolean, comparableProduct: IProduct): Observable<void> {

    let verifiedComparableProduct: IVerifiedComparableProduct = {
      externalId: comparableProduct.externalId,
      searchableProductId: comparableProduct.searchableProductId,
      vendor: comparableProduct.vendor,
      imageUrl: comparableProduct.imageUrl,
      url: comparableProduct.url,
      docId: comparableProduct.docId,
    };

    let verifyProductRequest: IChangeVerificationStatusRequest = {
      searchableProductId,
      comparableProduct: verifiedComparableProduct,
      isVerified
    }

    this.epLoadingService.start();
    return this.epApiService.post(`ComparableProducts/ChangeVerificationStatus`, verifyProductRequest).pipe(tap(() => {
      this.epLoadingService.stop("product verification status changed");
    }, err => {
      this.epLoadingService.stop("product verification status failed to change");
    }));
  }

}
