<div class="margin-bottom--m">
  <table mat-table matSort [dataSource]="_dataSource" multiTemplateDataRows>
    <!-- Image Column -->
    <ng-container matColumnDef="imageUrl">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Image</th>
      <td class="image-td" mat-cell *matCellDef="let element">
        <pinch-zoom *ngIf="element.imageUrl" backgroundColor="white" disableZoomControl="disable">
          <img loading="lazy" class="img-product-variant" [src]="element?.imageUrl" />
        </pinch-zoom>
      </td>
    </ng-container>

    <!-- Title Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="title-th variant-th center-div">Title</th>
      <td mat-cell *matCellDef="let element" class="padding-left--m">
        <div>
          {{ element.title }}
        </div>
      </td>
    </ng-container>

    <!-- SKU Column -->
    <ng-container matColumnDef="sku">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="sku-th variant-th center-div">SKU</th>
      <td mat-cell *matCellDef="let element" class="padding-left--m">
        <div>
          {{ element.sku }}
        </div>
      </td>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="price-th variant-th center-div">Price</th>
      <td mat-cell *matCellDef="let element" class="padding-left--m">
        <div>
          {{ element.price }}
        </div>
      </td>
    </ng-container>

    <!-- Cost Column -->
    <ng-container matColumnDef="cost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="cost-th center-div">Cost</th>
      <td mat-cell *matCellDef="let element" class="padding-left--m">
        <div>
          {{ element.cost }}
        </div>
      </td>
    </ng-container>

    <!-- Cost Column -->
    <ng-container matColumnDef="availableQuantity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="available-quantity-th center-div">Available Quantity</th>
      <td mat-cell *matCellDef="let element" class="padding-left--m">
        <div>
          {{ element.availableQuantity }}
        </div>
      </td>
    </ng-container>

    <!-- Actions Column -->    
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <pm-action-items 
        [isProductsActions]="true"    
        [isShowDownloadButton]="false"      
        [isShowDetailsButton]="false"      
        [isShowEditButton]="false"      
        [shouldShowDeleteButton]="false" 
        [shouldShowCopyButtons]="false"
        [shouldShowUpdateCompetitors]="false"
        [shouldShowActivateButton]="false"
        [isShowAdminButton]="false"      
        [shouldShowSeperateVariants]="isAdmin"
        (separateVariantsEmitter)="separateSingleVariant($event)" [item]="element">
      </pm-action-items>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
  </table>

  <table class="empty-table">
    <tr *ngIf="isLoading">
      <td style="text-align: center;padding: 10px;" [attr.colspan]="columns.length">
        Loading, please wait...
      </td>
    </tr>
    <tr *ngIf="(_dataSource && _dataSource.data.length <= 0) && !isLoading">
      <td style="text-align: center;padding: 10px;" [attr.colspan]="columns.length">
        No variants found.
      </td>
    </tr>
  </table>

  <div>
    <mat-paginator
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 20, 50]"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
