import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SessionService } from 'app/common/services/session.service';
import { isNumber } from 'util';

@Component({
  selector: 'ep-file-upload-input',
  templateUrl: './ep-file-upload-input.component.html',
  styleUrls: ['./ep-file-upload-input.component.scss']
})
export class EpFileUploadInputComponent implements OnInit {

  constructor(
    private sessionService: SessionService,
  ) {
  }


  @Input() accept;
  @Input() icon = 'publish';
  @Input() text = 'Upload';
  @Input() right = false;
  @Input() hideOutput = false;
  @ViewChild('fileUploadElement') fileUploadElement;
  @Input() set maxSize(size) { this._maxSize = parseFloat(size); }
  @Input() set minSize(size) { this._minSize = parseFloat(size); }
  @Input() multiple = null;
  @Input() primaryColor = true;
  @Input() color = null;
  @Input() buttonColor = "purple";
  @Output() onFileChange = new EventEmitter();
  @Output() onFileCancel = new EventEmitter();
  @Output() onFileDelete = new EventEmitter();
  @Output() openUploadModalEmitter = new EventEmitter();
  _maxSize = null;
  _minSize = null;
  _size = null;
  isNumber = isNumber
  _fileOutput: File;
  _fileUploaded: string = '';
  errors: { fileTooBig?, fileTooSmall?, wrongType?} = {};
  cssClass: any = { right: this.right };
  get minSize() { return this._minSize; }
  get maxSize() { 
    if(this.sessionService.isAdmin){
      return 150;
    }
    return this._maxSize; 
  }
  set size(size) { this._size = parseFloat(size); }
  get size() { return this._size; }

  set fileOutput(file: File) {
    if (!file) {
      return;
    }
    if (!!file) {
      this.resetFile(true);
    }
    this.size = ((file.size / 1024) / 1024).toFixed(4);
    const acceptTypes: string[] = this.accept instanceof String ? this.accept.split(',') : [];
    this.errors = {
      fileTooBig: this.isNumber(this.maxSize) && this.size > this.maxSize,
      fileTooSmall: this.isNumber(this.minSize) && this.size < this.minSize,
      wrongType: acceptTypes.length > 0 ? !acceptTypes.find(a => !!file.name.match(a)) : false,
    };
    if (this.errors.fileTooBig || this.errors.fileTooSmall || this.errors.wrongType) {
      this.resetFile(true);
      return;
    }
    this._fileOutput = file;
    this.onFileChange.emit(file);
  }
  get fileOutput(): File { return this._fileOutput; }

  set fileUploaded(uploadedFile: string) {
    this._fileUploaded = uploadedFile;
  }
  get fileUploaded(): string { return this._fileUploaded; }

  get noFileIsAttached(): boolean {
    return !((this.fileOutput && this.fileOutput.name) || this.fileUploaded);
  }

  ngOnInit() {
    this.cssClass['gw-text-link link-color'] = this.primaryColor;
  }
  stopEvent(e: Event) { e.preventDefault(); e.stopPropagation(); }
  openUploadFilePopup() {
    this.openUploadModalEmitter.emit();
  }
  chosenFile(e) {
    Object.keys(e.target.files).forEach((key) => {
      this.fileOutput = e.target.files[key];
    });
  }
  dragenter() { this.cssClass.dragover = true; }
  dragleave() { this.cssClass.dragover = false; }
  onDrop(e) {
    this.stopEvent(e);
    this.cssClass.dragover = false;
    this.fileOutput = e.dataTransfer.files[0];
  }
  resetFile(preventRecall?: boolean) {
    const fileOutput = preventRecall ? '_fileOutput' : 'fileOutput';
    this[fileOutput] = null;
    this.fileUploadElement.nativeElement.value = null;
    this.fileUploaded = "";
    this.onFileChange.emit(null);
  }
  fileDeleteEmitter() {
    this.onFileDelete.emit();
  }
  fileCancelEmitter() {
    this.onFileCancel.emit();
  }
  cancelUploadedFile(event: Event) {
    this.resetFile(true);
    this.stopEvent(event);
    this.fileCancelEmitter();
  }
  deleteUploadedFile(event: Event) {
    this.fileDeleteEmitter();
    this.stopEvent(event);
    this.resetFile();
  }

  browseFile() {
    this.fileUploadElement.nativeElement.click();
  }
}
