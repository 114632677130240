import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { escapeLabel, formatLabel } from "@swimlane/ngx-charts";
import { ColorScheme } from "app/common/constants";
import { ISeriesItem } from "app/common/interfaces/ISeriesItem";

@Component({
  selector: "pie-grid",
  templateUrl: "./pie-grid.component.html",
  styleUrls: ["./pie-grid.component.scss"],
})
export class PieGridComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  view: [number, number] = undefined;
  colorScheme = { ...ColorScheme };
  @Input() height: number = 300;
  @Input() animations: boolean = true;
  @Input() tooltipDisabled = false;
  @Input() data: ISeriesItem[];
  @Input() isSocialNetworkDistribution: boolean = false;

  @ViewChild("containerRef") containerRef: ElementRef;

  constructor(private cdf: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event("resize"));
  }

  ngAfterViewChecked(): void {
    this.cdf.detectChanges();
  }

  pieTooltipText({ data }) {
    const label = formatLabel(data.name);
    const val = formatLabel(data.value);

    return `
      <span class="tooltip-label">${escapeLabel(label)}</span>
      <span class="tooltip-val">$${val}</span>
    `;
  }

  select(data) {
    console.log("Item clicked", JSON.parse(JSON.stringify(data)));
  }

  activate(data) {
    console.log("Activate", JSON.parse(JSON.stringify(data)));
  }

  deactivate(data) {
    console.log("Deactivate", JSON.parse(JSON.stringify(data)));
  }

  onResize(event): void {
    if (this.elementRefWidth) {
      this.view = [this.elementRefWidth, this.height];
    }
  }

  get elementRefWidth(): number {
    return this.containerRef?.nativeElement?.offsetWidth;
  }
}
