import { EventEmitter, Injectable } from '@angular/core';
import { createStore, withProps, select, Store } from '@ngneat/elf';
import { withEntities, selectAllEntities, setEntities, hasEntity, updateEntities, upsertEntities, getAllEntities, getEntity } from '@ngneat/elf-entities';
import { ISearchableProduct } from 'app/common/interfaces/ISearchableProduct';
import { ILastSearchMetadata } from 'app/common/interfaces/searchableProducts/ILastSearchMetadata';

const store = createStore(
  { name: 'searchableProducts' },  
  withEntities<ISearchableProduct>()
);

export const searchableProducts$ = store.pipe(selectAllEntities());

export function setSearchableProducts(searchableProducts: ISearchableProduct[]) {
  store.update(setEntities(searchableProducts));
}

@Injectable({
  providedIn: 'root'
})
export class SearchableProductsRepository {

  productsListChangedEmitter = new EventEmitter<Object>();

  searchableProducts$ = store.pipe(selectAllEntities());

  getStore() : Store{
    return store;
  }
  setProducts(searchableProducts: ISearchableProduct[]) : void {
    store.update(setEntities(searchableProducts));
    this.productsListChangedEmitter.emit();
  }
  
  addOrUpdateProduct(searchableProduct : ISearchableProduct) : void{
    store.update(upsertEntities(searchableProduct));
    this.productsListChangedEmitter.emit();
  }

  updateLastSearchForProduct(searchableProductId: string, lastSearchMetadata: ILastSearchMetadata) {
    const searchableProduct = this.getById(searchableProductId);
    if(searchableProduct == null){
      return;
    }

    searchableProduct.lastSearch = lastSearchMetadata;
    store.update(upsertEntities(searchableProduct));
  }

  getById(searchableProductId : string){
    return store.query(getEntity(searchableProductId));
  }

  getAllProducts() : ISearchableProduct[]{
    return store.query(getAllEntities());
  }
}

export const searchableProductsRepository = new SearchableProductsRepository();
