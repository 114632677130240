import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { SessionService } from '../services/session.service';

@Directive({
  selector: '[disableElementForDemoPlatform]'
})
export class DisableElementDirective {
  constructor(private elementRef: ElementRef, private renderer: Renderer2, private sessionService: SessionService) { }

  ngAfterViewInit(): void {
    this.disable();
  }

  private disable() {
    if (this.sessionService.isDemoPlatform) {
      const elementToDisable = this.renderer.selectRootElement(this.elementRef.nativeElement, true);
      elementToDisable.disabled = true;
    }
  }

}
