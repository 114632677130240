import * as _ from 'lodash';

export interface IAnyObj {
  [key: string]: any;
  [key: number]: any;
}

export class GwObjectHelper {

  public static nameof<T>(name: keyof T) {
    return name;
  }

  public static removeKey(object: { [key: string]: any }, objectKey: string | number)
    : { [key: string]: any } {
    if (!object) {
      return null;
    }

    let copy = Object.assign({}, object);
    if (!copy[objectKey]) {
      return copy;
    }

    delete copy[objectKey];
    if (Object.keys(copy).length === 0) {
      copy = null;
    }

    return copy;
  }

  public static isDeepEqual(item1: any, item2: any): boolean {
    return JSON.stringify(item1) === JSON.stringify(item2);
  }

  public static generateUniqueId(): string {
    return `${Math.random().toString(36).substr(2, 9)}-${Date.now()}`;
  }

  public static hasAllKeys = (obj: IAnyObj, keys: string[]): boolean =>
    _.every(keys, _.partial(_.has, obj))
}
